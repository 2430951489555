<template>
   <b-form class="wl-form__login">
      <b-form-group label="" label-for="email" class="mb-3">
            <b-form-input
                id="email"
                type="email"
                :placeholder="$t('E_MAIL')"
                required
                v-model.trim="email"
            ></b-form-input>
        </b-form-group>

        <b-form-group label="" label-for="password" class="mb-lg-5 mb-4">
          <b-form-input
              type="password"
              id="password"
              :placeholder="$t('PASSWORD')"
              required
              v-model.trim="password"
          ></b-form-input>
        </b-form-group>

        <b-form-group class="text-center">
          <b-button type="button" variant="cta-secondary" @click="sendLogin">
            {{ $t('SIGN_IN') }}
          </b-button>
         
        </b-form-group>
      </b-form>
</template>

<script>

import axios from 'axios';
import { mapMutations, mapGetters, mapActions } from 'vuex';

export default {
  name: "areaLogin",
  components: {
  },
  data() {
    return {
      API_URL: process.env.VUE_APP_API,
      email: '',
      password: '',
      autoHideToast: 4000
    }
  },
  methods: {
    ...mapMutations([
    ]),
    ...mapActions([
        'setIsLoggedIn',
        'setUser',
        'setCompany',
        'setCategories'
    ]),
    ...mapGetters([
    ]),
    async sendLogin() {
      //console.log(this.email);
      //console.log(this.password);
      //console.log(this.API_URL);

      await this.$root.$emit('track', {source: null, target: null, button: 'loginSendForm'});

      //todo: local development
      var cookie = this.$store.getters.getToken;

      const response = await axios.post(
          this.API_URL,
          {
            cookie: cookie,
            type: 'login',
            email: this.email,
            password: this.password,
          }
      );

      //console.log(response);

      if (!response.data.login) {

        console.log('login fehlgeschlagen');
        //console.log(response);
        //console.log(response.data.error);
        const loginErrorMessage = response.data.error.join("<br/>");
        this.$bvToast.toast([this.$options.filters.striptags(loginErrorMessage)], {
          title: [this.$t('ERROR_TITLE_DEFAULT')],
          solid: true,
          toaster: 'b-toaster-top-center',
          variant: 'danger',
          noCloseButton: true,
          autoHideDelay: this.autoHideToast
        })

      } else {

        console.log('login erfolgreich');

        this.$store.dispatch('setIsLoggedIn', true);
        this.$store.dispatch('setUser', response.data.user);
        this.$store.dispatch('setToken', response.data.token);

        this.getCategories();

        this.$router.push('/dashboard');

        /*
        if (response.data.user.user_level != 30) {
          this.$router.push('/recording');
        } else {
          this.$router.push('/statistic');
        }

         */


      }
    },
    async getCategories() {

      //todo: local development
      var cookie = this.$store.getters.getToken;

      const response = await axios.post(
          this.API_URL,
          {
            type: 'get_categories',
            cookie: cookie
          }
      );

      //console.log('checkmail');
      //console.log(response);
      //console.log('getcompanies response: ', response.data);

      this.$store.dispatch('setCategories', response.data);

    },
  }
}
</script>

<style scoped>

</style>