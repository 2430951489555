import { render, staticRenderFns } from "./areaAiTools.vue?vue&type=template&id=72a16436&scoped=true"
import script from "./areaAiTools.vue?vue&type=script&lang=js"
export * from "./areaAiTools.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "72a16436",
  null
  
)

export default component.exports