<template>
  <b-container>

    <vue-dropzone
        ref="whitepaperDropzone"
        id="dropzoneWhitepaper"
        :options="dropzoneOptions"
        v-on:vdropzone-sending="uploadEvent"
        v-on:vdropzone-success="fileUploaded"
    ></vue-dropzone>

    <div
        v-for="(media, media_index) in getMedia"
        :key="media_index"
    >

      <b-row
          v-if="media_index == 0"
          class="media-line mt-5">

        <b-col cols="3">

          <h5>Icon</h5>

        </b-col>

        <b-col cols="5">

          <h5>Dateiname</h5>

        </b-col>

        <b-col cols="3">
          <h5>Medien-Typ</h5>
        </b-col>

        <b-col cols="1">
          <h5>Upload</h5>
        </b-col>

      </b-row>

      <b-row class="media-line mt-2">

          <b-col cols="3" class="pt-10 pb-10 text-center">

            <span
                v-if="createTag(media) !== ''"
                v-html="createTag(media)"></span>

            <span
                v-if="media.media_type_description === 'URL'"
            >
              <font-awesome-icon
                  :icon="['fa-solid', 'fa-link']"
                  class="link-primary media-icon"
              />
            </span>

            <span
                v-if="media.media_type_description === 'Dokument'"
            >
              <font-awesome-icon
                  :icon="['fa-solid', 'fa-file']"
                  class="link-primary media-icon"
              />
            </span>

          </b-col>

        <b-col cols="5" class="pt-10 pb-10">

          <b-button
              type="button"
              variant="link"
              class="link-primary text-wrap text-left"
              @click="copyText(media.ressource)">{{ media.ressource.slice(0,200) }}</b-button>

        </b-col>

        <b-col cols="3" class="pt-10 pb-10">
          {{ media.media_type_description }}
        </b-col>

        <b-col cols="1" class="pt-10 pb-10">
          {{ media.insert_date_formatted }}
        </b-col>

      </b-row>

    </div>

    <p class="mb-20">&nbsp;</p>

  </b-container>
</template>

<script>

import {mapActions, mapGetters, mapMutations} from "vuex";
import vue2Dropzone from 'vue2-dropzone';
import 'vue2-dropzone/dist/vue2Dropzone.min.css';
import axios from "axios";
//import axios from "axios";
/* https://rowanwins.github.io/vue-dropzone/docs/dist/#/events */
const CONFIG = require(`@/wl-config/${process.env.VUE_APP_WL_FOLDER}/wl-app-config`)

export default {
  name: "productEditWhitepaper",
  props: ['product_id'],
  components: {
    vueDropzone: vue2Dropzone,
  },
  data() {
    return {

      API_URL_UPLOAD: process.env.VUE_APP_UPLOAD,
      API_URL: process.env.VUE_APP_API,
      headerInfo: CONFIG.WLCONFG.META_SAFE_EQUIV,

      dropzoneOptions: {
        url: process.env.VUE_APP_UPLOAD,
        thumbnailWidth: 150,
        maxFilesize: 100,
        addRemoveLinks: true,
        dictDefaultMessage: "<i class='fa fa-cloud-upload'></i>Lade hier das PDF für das Whitepaper hoch.",
        headers: { headerInfo: "whitepaperUpload" },
        init: function ()  {
          this.on("error", function (file, message) {
            alert(message);
            this.removeFile(file);
          });
        },
        acceptedFiles: ".pdf,.PDF",
      },

    }
  },
  created() {

    this.getWhitepaperData();

    //console.log('id', this.getActualWhitepaperId);

  },
  computed: {
    ...mapGetters([
        'getWhitepaperMedia',
        'getActualWhitepaperId'
    ]),
    ...mapActions([
    ]),
    getMedia() {
      return this.getWhitepaperMedia;
    },
  },
  methods: {
    ...mapMutations([
    ]),
    ...mapActions([
        'setActualWhitepaperId',
        'setWhitepaperMedia',
        'setWhitepaperMediaItem',
    ]),
    ...mapGetters([
    ]),
    uploadEvent (file, xhr, formData) {

      console.log(xhr);
      //console.log(formData);

      if (this.getActualWhitepaperId > 0) {
        formData.append('product_whitepaper_id', this.getActualWhitepaperId);
      }
    },
    fileUploaded(file, response) {
      //console.log(file);
      //console.log(response);

      let result = JSON.parse(response);

      console.log(result.product_media_id);

      if (result.product_media_id === undefined) return;

      this.$store.dispatch('setActualWhitepaperId', result.product_whitepaper_id);
      this.$store.dispatch('setWhitepaperMediaItem', result);


    },
    createTag(media) {

      if(media.media_type_description === undefined) return '';

      var str = '';

      switch(media.media_type_description) {

        case 'Bild':
          str += '<img src="' + media.url + '" width="100" border="0"/>';
          break;

        case 'Dokument':
          str += '';
          break;

        case 'Vimeo':
          //str = '<iframe src="http://player.vimeo.com/video/' + media.filename_no_extension + '" width="100%" frameborder="0" allow="autoplay; fullscreen" allowfullscreen></iframe>';

          str = '<div style="padding:51.3% 0 0 0;position:relative;"><iframe src="https://player.vimeo.com/video/' + media.filename_no_extension + '?h=1b6c086b95&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen style="position:absolute;top:0;left:0;width:100%;height:100%;" title="Back to Top Link"></iframe></div>';
          break;

        case 'Youtube':
          str = '<iframe width="100%" src="https://www.youtube-nocookie.com/embed/' + media.filename_no_extension + '" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>';
          break;

        case 'URL':
          str += "";
          break;

      }

      //console.log(str);

      return str;

    },
    copyText(content) {
      navigator.clipboard.writeText(content);
    },
    async getWhitepaperData() {

      this.$store.dispatch('setActualWhitepaperId', 0);
      this.$store.dispatch('setWhitepaperMedia', {});

      //todo: local development
      var cookie = this.$store.getters.getToken;

      const response = await axios.post(
          this.API_URL,
          {
            type: 'get_product_whitepaper_data',
            product_id: this.product_id,
            cookie: cookie
          }
      );

      //console.log(response.data);

      //console.log('id', response.data.product_whitepaper_id);

      if (response.data.product_whitepaper_id === undefined) return;

      this.$store.dispatch('setActualWhitepaperId', response.data.product_whitepaper_id);

      if (response.data.media === undefined) return;

      this.$store.dispatch('setWhitepaperMedia', response.data.media);

    },
  }
}
</script>

<style scoped>

.media-icon {
  font-size: 30pt;
}

.mb-20 {
  margin-bottom: 20px;
}

.text-wrap {
  overflow-wrap: anywhere;
}

</style>