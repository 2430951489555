import Vue from "vue";

const state = {

    productList: [],
    productTypes: {},
    myProductList: {},
    checklistItems: [
        {
            product_checklist_main_category_id: 1,
            product_checklist_id: 1,
            category_title: 'Hauptkategorie 1 mit ganz vielen Bananen oh ja!',
            category_description: 'Lorem Ipsum',
            category_sort: 0,
            category_weight: 5,
            category_icon: 'fa-tag',
            edit: false,
            sub_categories: [
                {
                    product_checklist_sub_category_id: 1,
                    product_checklist_main_category_id: 1,
                    category_title: 'Unterkategorie 1',
                    category_description: 'Lorem Ipsum',
                    category_sort: 0,
                    category_icon: 'fa-tag',
                    edit: false,
                    items: [
                        {
                            product_checklist_item_id: 1,
                            product_checklist_sub_category_id: 1,
                            item_description: 'Item 1',
                            item_impact: 5,
                            item_cost: 6,
                            item_priority: 8,
                            item_example: 'Lorem Ipsum',
                            edit: false,
                        },
                        {
                            product_checklist_item_id: 2,
                            product_checklist_sub_category_id: 1,
                            item_description: 'Item 1',
                            item_impact: 5,
                            item_cost: 6,
                            item_priority: 8,
                            item_example: 'Lorem Ipsum',
                            edit: false,
                        },
                        {
                            product_checklist_item_id: 3,
                            product_checklist_sub_category_id: 1,
                            item_description: 'The store offers upsell opportunities between the checkout page and thank you page; if the user decides to add another product to the order, he doesn\'t need to input all the payment info again',
                            item_impact: 5,
                            item_cost: 6,
                            item_priority: 8,
                            item_example: 'Lorem Ipsum',
                            edit: false,
                        },
                        {
                            product_checklist_item_id: 2,
                            product_checklist_sub_category_id: 1,
                            item_description: 'Navigation items are ordered in the most logical or task-oriented manner (with the less important corporate information at the bottom)',
                            item_impact: 7,
                            item_cost: 8,
                            item_priority: 2,
                            item_example: 'Lorem Ipsum',
                            edit: false,
                        },
                    ]
                },
                {
                    product_checklist_sub_category_id: 1,
                    product_checklist_main_category_id: 1,
                    category_title: 'Unterkategorie 1',
                    category_description: 'Lorem Ipsum',
                    category_sort: 0,
                    category_icon: 'fa-tag',
                    edit: false,
                    items: [
                        {
                            product_checklist_item_id: 1,
                            product_checklist_sub_category_id: 1,
                            item_description: 'Item 1',
                            item_impact: 5,
                            item_cost: 6,
                            item_priority: 8,
                            item_example: 'Lorem Ipsum',
                            edit: false,
                        },
                        {
                            product_checklist_item_id: 2,
                            product_checklist_sub_category_id: 1,
                            item_description: 'Item 1',
                            item_impact: 5,
                            item_cost: 6,
                            item_priority: 8,
                            item_example: 'Lorem Ipsum',
                            edit: false,
                        },
                        {
                            product_checklist_item_id: 3,
                            product_checklist_sub_category_id: 1,
                            item_description: 'Item 1',
                            item_impact: 5,
                            item_cost: 6,
                            item_priority: 8,
                            item_example: 'Lorem Ipsum',
                            edit: false,
                        },
                    ]
                },
                {
                    product_checklist_sub_category_id: 1,
                    product_checklist_main_category_id: 1,
                    category_title: 'Unterkategorie 1',
                    category_description: 'Lorem Ipsum',
                    category_sort: 0,
                    category_icon: 'fa-tag',
                    edit: false,
                    items: [
                        {
                            product_checklist_item_id: 1,
                            product_checklist_sub_category_id: 1,
                            item_description: 'Item 1',
                            item_impact: 5,
                            item_cost: 6,
                            item_priority: 8,
                            item_example: 'Lorem Ipsum',
                            edit: false,
                        },
                        {
                            product_checklist_item_id: 2,
                            product_checklist_sub_category_id: 1,
                            item_description: 'Item 1',
                            item_impact: 5,
                            item_cost: 6,
                            item_priority: 8,
                            item_example: 'Lorem Ipsum',
                            edit: false,
                        },
                        {
                            product_checklist_item_id: 3,
                            product_checklist_sub_category_id: 1,
                            item_description: 'Item 1',
                            item_impact: 5,
                            item_cost: 6,
                            item_priority: 8,
                            item_example: 'Lorem Ipsum',
                            edit: false,
                        },
                    ]
                },
            ]
        },
        {
            product_checklist_main_category_id: 1,
            product_checklist_id: 1,
            category_title: 'Hauptkategorie 1',
            category_description: 'Lorem Ipsum',
            category_sort: 0,
            category_weight: 5,
            category_icon: 'fa-tag',
            edit: false,
            sub_categories: [
                {
                    product_checklist_sub_category_id: 1,
                    product_checklist_main_category_id: 1,
                    category_title: 'Unterkategorie 1',
                    category_description: 'Lorem Ipsum',
                    category_sort: 0,
                    category_icon: 'fa-tag',
                    edit: false,
                    items: [
                        {
                            product_checklist_item_id: 1,
                            product_checklist_sub_category_id: 1,
                            item_description: 'Item 1',
                            item_impact: 5,
                            item_cost: 6,
                            item_priority: 8,
                            item_example: 'Lorem Ipsum',
                            edit: false,
                        },
                        {
                            product_checklist_item_id: 2,
                            product_checklist_sub_category_id: 1,
                            item_description: 'Item 1',
                            item_impact: 5,
                            item_cost: 6,
                            item_priority: 8,
                            item_example: 'Lorem Ipsum',
                            edit: false,
                        },
                        {
                            product_checklist_item_id: 3,
                            product_checklist_sub_category_id: 1,
                            item_description: 'Item 1',
                            item_impact: 5,
                            item_cost: 6,
                            item_priority: 8,
                            item_example: 'Lorem Ipsum',
                            edit: false,
                        },
                    ]
                },
                {
                    product_checklist_sub_category_id: 1,
                    product_checklist_main_category_id: 1,
                    category_title: 'Unterkategorie 1',
                    category_description: 'Lorem Ipsum',
                    category_sort: 0,
                    category_icon: 'fa-tag',
                    edit: false,
                    items: [
                        {
                            product_checklist_item_id: 1,
                            product_checklist_sub_category_id: 1,
                            item_description: 'Item 1',
                            item_impact: 5,
                            item_cost: 6,
                            item_priority: 8,
                            item_example: 'Lorem Ipsum',
                            edit: false,
                        },
                        {
                            product_checklist_item_id: 2,
                            product_checklist_sub_category_id: 1,
                            item_description: 'Item 1',
                            item_impact: 5,
                            item_cost: 6,
                            item_priority: 8,
                            item_example: 'Lorem Ipsum',
                            edit: false,
                        },
                        {
                            product_checklist_item_id: 3,
                            product_checklist_sub_category_id: 1,
                            item_description: 'Item 1',
                            item_impact: 5,
                            item_cost: 6,
                            item_priority: 8,
                            item_example: 'Lorem Ipsum',
                            edit: false,
                        },
                    ]
                },
                {
                    product_checklist_sub_category_id: 1,
                    product_checklist_main_category_id: 1,
                    category_title: 'Unterkategorie 1',
                    category_description: 'Lorem Ipsum',
                    category_sort: 0,
                    category_icon: 'fa-tag',
                    edit: false,
                    items: [
                        {
                            product_checklist_item_id: 1,
                            product_checklist_sub_category_id: 1,
                            item_description: 'Item 1',
                            item_impact: 5,
                            item_cost: 6,
                            item_priority: 8,
                            item_example: 'Lorem Ipsum',
                            edit: false,
                        },
                        {
                            product_checklist_item_id: 2,
                            product_checklist_sub_category_id: 1,
                            item_description: 'Item 1',
                            item_impact: 5,
                            item_cost: 6,
                            item_priority: 8,
                            item_example: 'Lorem Ipsum',
                            edit: false,
                        },
                        {
                            product_checklist_item_id: 3,
                            product_checklist_sub_category_id: 1,
                            item_description: 'Item 1',
                            item_impact: 5,
                            item_cost: 6,
                            item_priority: 8,
                            item_example: 'Lorem Ipsum',
                            edit: false,
                        },
                    ]
                },
            ]
        },
        {
            product_checklist_main_category_id: 1,
            product_checklist_id: 1,
            category_title: 'Hauptkategorie 1',
            category_description: 'Lorem Ipsum',
            category_sort: 0,
            category_weight: 5,
            category_icon: 'fa-tag',
            edit: false,
            sub_categories: [
                {
                    product_checklist_sub_category_id: 1,
                    product_checklist_main_category_id: 1,
                    category_title: 'Unterkategorie 1',
                    category_description: 'Lorem Ipsum',
                    category_sort: 0,
                    category_icon: 'fa-tag',
                    edit: false,
                    items: [
                        {
                            product_checklist_item_id: 1,
                            product_checklist_sub_category_id: 1,
                            item_description: 'Item 1',
                            item_impact: 5,
                            item_cost: 6,
                            item_priority: 8,
                            item_example: 'Lorem Ipsum',
                            edit: false,
                        },
                        {
                            product_checklist_item_id: 2,
                            product_checklist_sub_category_id: 1,
                            item_description: 'Item 1',
                            item_impact: 5,
                            item_cost: 6,
                            item_priority: 8,
                            item_example: 'Lorem Ipsum',
                            edit: false,
                        },
                        {
                            product_checklist_item_id: 3,
                            product_checklist_sub_category_id: 1,
                            item_description: 'Item 1',
                            item_impact: 5,
                            item_cost: 6,
                            item_priority: 8,
                            item_example: 'Lorem Ipsum',
                            edit: false,
                        },
                    ]
                },
                {
                    product_checklist_sub_category_id: 1,
                    product_checklist_main_category_id: 1,
                    category_title: 'Unterkategorie 1',
                    category_description: 'Lorem Ipsum',
                    category_sort: 0,
                    category_icon: 'fa-tag',
                    edit: false,
                    items: [
                        {
                            product_checklist_item_id: 1,
                            product_checklist_sub_category_id: 1,
                            item_description: 'Item 1',
                            item_impact: 5,
                            item_cost: 6,
                            item_priority: 8,
                            item_example: 'Lorem Ipsum',
                            edit: false,
                        },
                        {
                            product_checklist_item_id: 2,
                            product_checklist_sub_category_id: 1,
                            item_description: 'Item 1',
                            item_impact: 5,
                            item_cost: 6,
                            item_priority: 8,
                            item_example: 'Lorem Ipsum',
                            edit: false,
                        },
                        {
                            product_checklist_item_id: 3,
                            product_checklist_sub_category_id: 1,
                            item_description: 'Item 1',
                            item_impact: 5,
                            item_cost: 6,
                            item_priority: 8,
                            item_example: 'Lorem Ipsum',
                            edit: false,
                        },
                    ]
                },
                {
                    product_checklist_sub_category_id: 1,
                    product_checklist_main_category_id: 1,
                    category_title: 'Unterkategorie 1',
                    category_description: 'Lorem Ipsum',
                    category_sort: 0,
                    category_icon: 'fa-tag',
                    edit: false,
                    items: [
                        {
                            product_checklist_item_id: 1,
                            product_checklist_sub_category_id: 1,
                            item_description: 'Item 1',
                            item_impact: 5,
                            item_cost: 6,
                            item_priority: 8,
                            item_example: 'Lorem Ipsum',
                            edit: false,
                        },
                        {
                            product_checklist_item_id: 2,
                            product_checklist_sub_category_id: 1,
                            item_description: 'Item 1',
                            item_impact: 5,
                            item_cost: 6,
                            item_priority: 8,
                            item_example: 'Lorem Ipsum',
                            edit: false,
                        },
                        {
                            product_checklist_item_id: 3,
                            product_checklist_sub_category_id: 1,
                            item_description: 'Item 1',
                            item_impact: 5,
                            item_cost: 6,
                            item_priority: 8,
                            item_example: 'Lorem Ipsum',
                            edit: false,
                        },
                    ]
                },
            ]
        },
    ],
    courseItems: [
        {
            product_course_chapter_id: 1,
            product_course_id: 1,
            chapter_title: 'Kapitel 1',
            chapter_description: 'Lorem Ipsum',
            chapter_sort: 0,
            chapter_icon: 'fa-tag',
            edit: false,
            items: [
                {
                    product_course_item_id: 1,
                    product_course_chapter_id: 1,
                    item_title: 'Item Title',
                    item_description: 'Item 1',
                    item_sort: 0,
                    duration: 0,
                    edit: false,
                },
                {
                    product_course_item_id: 2,
                    product_course_chapter_id: 1,
                    item_title: 'Item Title 2',
                    item_description: 'Item 1',
                    item_sort: 0,
                    duration: 0,
                    edit: false,
                },
            ],
        },
    ],
    actualMainCategoryIndex: 0,
    actualSubCategoryIndex: 0,
    actualItemIndex: 0,
    actualSidebarArea: '',
    actualItemId: 0,
    actualWhitepaperId: 0,
    whitepaperMedia: [],
    productMedia: [],
    productMediaDescriptionTypes: [],

    actualCourseChapterIndex: 0,
    actualCourseItemIndex: 0,
    actualCourseSidebarArea: '',
    actualCourseItemId: 0,
}

const mutations = {

    SET_MY_PRODUCT_LIST(state, payload) {
        state.myProductList = payload;
    },

    SET_ACTUAL_MAIN_CATEGORY_INDEX(state, payload) {
        state.actualMainCategoryIndex = payload;
    },

    SET_ACTUAL_SUB_CATEGORY_INDEX(state, payload) {
        state.actualSubCategoryIndex = payload;
    },

    SET_ACTUAL_ITEM_INDEX(state, payload) {
        state.actualItemIndex = payload;
    },

    SET_ACTUAL_SIDEBAR_AREA(state, payload) {
        state.actualSidebarArea = payload;
    },

    SET_ACTUAL_ITEM_ID(state, payload) {
        state.actualItemId = payload;
    },

    SET_PRODUCT_LIST(state, payload) {
        state.productList = payload;
    },

    ADD_TO_PRODUCT_LIST(state, payload) {

        let productList = state.productList;
        var find = false;

        Object.keys(productList).forEach(index => {
            //console.log(index, productList[index]);

            //console.log(productList[index].product_id);

            if (productList[index].product_id === payload.product_id) {
                //state[key][el].active = active;
                //console.log(index);
                productList[index] = payload;
                find = true;
                //console.log('gefunden');
                //console.log(productList[index].product_id);
                state.productList = productList;
            }
        });

        if (!find) {
            //console.log('assign');
            //console.log(payload.product_id);
            Object.assign(productList, payload);
            state.productList = productList;
        }

        //console.log('neu');
        //console.log(state.productList);

    },

    SET_PRODUCT_TYPES(state, payload) {
        state.productTypes = payload;
    },

    SET_CHECKLIST_ITEMS(state, payload) {
        state.checklistItems = payload;
    },

    SET_MAIN_CATEGORY(state, payload) {

        if (payload.category_title !== undefined) {
            state.checklistItems[state.actualMainCategoryIndex].category_title = payload.category_title;
        }

        if (payload.category_description !== undefined) {
            state.checklistItems[state.actualMainCategoryIndex].category_description = payload.category_description;
        }

        if (payload.category_icon !== undefined) {
            state.checklistItems[state.actualMainCategoryIndex].category_icon = payload.category_icon;
        }

        if (payload.category_weight !== undefined) {
            state.checklistItems[state.actualMainCategoryIndex].category_weight = payload.category_weight;
        }

        if (payload.category_sort !== undefined) {
            state.checklistItems[state.actualMainCategoryIndex].category_sort = payload.category_sort;
        }

        /*
        let obj = state.checklistItems;

        obj.sort(function(a, b) {
            return parseFloat(a.category_sort) - parseFloat(b.category_sort);
        });

        obj.sort();

        state.checklistItems = obj;

         */

    },

    SET_SUB_CATEGORY(state, payload) {

        if (payload.category_title !== undefined) {
            state.checklistItems[state.actualMainCategoryIndex].sub_categories[state.actualSubCategoryIndex].category_title = payload.category_title;
        }

        if (payload.category_description !== undefined) {
            state.checklistItems[state.actualMainCategoryIndex].sub_categories[state.actualSubCategoryIndex].category_description = payload.category_description;
        }

        if (payload.category_icon !== undefined) {
            state.checklistItems[state.actualMainCategoryIndex].sub_categories[state.actualSubCategoryIndex].category_icon = payload.category_icon;
        }

        if (payload.category_sort !== undefined) {
            state.checklistItems[state.actualMainCategoryIndex].sub_categories[state.actualSubCategoryIndex].category_sort = payload.category_sort;
        }

    },

    SET_CHECKLIST_ITEM(state, payload) {

        if (payload.item_description !== undefined) {
            state.checklistItems[state.actualMainCategoryIndex].sub_categories[state.actualSubCategoryIndex].items[state.actualItemIndex].item_description = payload.item_description;
        }

        if (payload.item_impact !== undefined) {
            state.checklistItems[state.actualMainCategoryIndex].sub_categories[state.actualSubCategoryIndex].items[state.actualItemIndex].item_impact = payload.item_impact;
        }

        if (payload.item_cost !== undefined) {
            state.checklistItems[state.actualMainCategoryIndex].sub_categories[state.actualSubCategoryIndex].items[state.actualItemIndex].item_cost = payload.item_cost;
        }

        if (payload.item_priority !== undefined) {
            state.checklistItems[state.actualMainCategoryIndex].sub_categories[state.actualSubCategoryIndex].items[state.actualItemIndex].item_priority = payload.item_priority;
        }

        if (payload.item_example !== undefined) {
            state.checklistItems[state.actualMainCategoryIndex].sub_categories[state.actualSubCategoryIndex].items[state.actualItemIndex].item_example = payload.item_example;
        }

    },

    SET_CHECKLIST_ITEM_MEDIA(state, payload) {

        //console.log('checklist media');
        //console.log(payload);

        let checklist = state.checklistItems;

        let media = checklist[state.actualMainCategoryIndex].sub_categories[state.actualSubCategoryIndex].items[state.actualItemIndex];
        //console.log(media);
        if (media === undefined) return;

        if (media.media === undefined) {
            media.media = [];
        }

        for (var index in media.media) {

            if (media.media[index].md5 == payload.item.md5) {
                //console.log('doppelten Eintrag gefunden');
                checklist[state.actualMainCategoryIndex].sub_categories[state.actualSubCategoryIndex].items[state.actualItemIndex].media[index] = payload.item;

                //state.checklistItems = checklist;
                Vue.set(state.checklistItems, state.actualMainCategoryIndex, checklist[state.actualMainCategoryIndex]);

                return;
            }

        }

        checklist[state.actualMainCategoryIndex].sub_categories[state.actualSubCategoryIndex].items[state.actualItemIndex].media.push(payload.item);

        //state.checklistItems = checklist;
        Vue.set(state.checklistItems, state.actualMainCategoryIndex, checklist[state.actualMainCategoryIndex]);

    },

    SET_MEDIA_TEST_DATA(state, payload) {

        //let media = state.checklistItems[1].sub_categories[0].items[0];

        console.log(payload);

        let media = [

            {

                "product_checklist_item_id":"1",

                "product_id":null,

                "ressource":"Wohnzimmer-mit-Vespa.png",

                "filename_no_extension":"Wohnzimmer-mit-Vespa",

                "extension":"png",

                "path":"/var/www/member.myshopbooster.de/html/files/checklist_items/2022/07/1/Wohnzimmer-mit-Vespa.png",

                "md5":"34c663efad984285cb42178c3e16f89c",

                "product_media_type_id":2,

                "media_type_description":"Bild",

                "product_media_id":"33",

                "identifier":"{33:Wohnzimmer-mit-Vespa.png}",

                "url":"https://member.myshopbooster.de/server/files.php?type=get_product_checklist_media&key=34c663efad984285cb42178c3e16f89c&product_media_id=33"

            }

        ];

        state.checklistItems[state.actualMainCategoryIndex].category_title = 'banane';
        state.checklistItems[state.actualMainCategoryIndex].sub_categories[state.actualSubCategoryIndex].items[state.actualItemIndex].media = media;

    },

    SET_ACTUAL_WHITEPAPER_ID(state, payload) {
        state.actualWhitepaperId = payload;
    },

    SET_WHITEPAPER_MEDIA_ITEM(state, payload) {
        //state.whitepaperMedia = payload;

        let mediaList = state.whitepaperMedia;

        /*
        let media = checklist[state.actualMainCategoryIndex].sub_categories[state.actualSubCategoryIndex].items[state.actualItemIndex];
        //console.log(media);
        if (media === undefined) return;

        if (media.media === undefined) {
            media.media = [];
        }

         */

        for (var index in mediaList) {

            if (mediaList[index].md5 == payload.md5) {
                //console.log('doppelten Eintrag gefunden');
                mediaList[index] = payload;

                state.whitepaperMedia = mediaList;
                //Vue.set(state.whitepaperMedia, state.actualMainCategoryIndex, checklist[state.actualMainCategoryIndex]);

                return;
            }

        }

        mediaList.push(payload);

        state.whitepaperMedia = mediaList;
        //Vue.set(state.checklistItems, state.actualMainCategoryIndex, checklist[state.actualMainCategoryIndex]);
    },

    SET_WHITEPAPER_MEDIA(state, payload) {

        state.whitepaperMedia = payload;

    },

    SET_PRODUCT_MEDIA_ITEM(state, payload) {
        //state.whitepaperMedia = payload;

        let mediaList = state.productMedia;

        /*
        let media = checklist[state.actualMainCategoryIndex].sub_categories[state.actualSubCategoryIndex].items[state.actualItemIndex];
        //console.log(media);
        if (media === undefined) return;

        if (media.media === undefined) {
            media.media = [];
        }

         */

        for (var index in mediaList) {

            if (mediaList[index].md5 == payload.md5) {
                //console.log('doppelten Eintrag gefunden');
                mediaList[index] = payload;

                state.productMedia = mediaList;
                //Vue.set(state.whitepaperMedia, state.actualMainCategoryIndex, checklist[state.actualMainCategoryIndex]);

                return;
            }

        }

        mediaList.push(payload);

        state.productMedia = mediaList;
        //Vue.set(state.checklistItems, state.actualMainCategoryIndex, checklist[state.actualMainCategoryIndex]);
    },

    SET_PRODUCT_MEDIA(state, payload) {

        state.productMedia = payload;

    },

    SET_PRODUCT_MEDIA_DESCRIPTION_TYPES(state, payload) {

        state.productMediaDescriptionTypes = payload;

    },

    UPDATE_PRODUCT_IS_MY_PRODUCT(state, payload) {

        Object.keys(state.myProductList).forEach((key) => {

            if (payload == state.myProductList[key].product_id) {

                state.myProductList[key].is_my_product = 1;

            }

        });

    },

    SET_ACTUAL_COURSE_CHAPTER_INDEX(state, payload) {
        state.actualCourseChapterIndex = payload;
    },

    SET_ACTUAL_COURSE_ITEM_INDEX(state, payload) {
        state.actualCourseItemIndex = payload;
    },

    SET_ACTUAL_COURSE_ITEM_ID(state, payload) {
        state.actualCourseItemId = payload;
    },

    SET_ACTUAL_COURSE_SIDEBAR_AREA(state, payload) {
        state.actualCourseSidebarArea = payload;
    },

    SET_COURSE_ITEM(state, payload) {

        if (payload.item_title !== undefined) {
            state.courseItems[state.actualCourseChapterIndex].items[state.actualCourseItemIndex].item_title = payload.item_title;
        }

        if (payload.item_description !== undefined) {
            state.courseItems[state.actualCourseChapterIndex].items[state.actualCourseItemIndex].item_description = payload.item_description;
        }

        if (payload.item_sort !== undefined) {
            state.courseItems[state.actualCourseChapterIndex].items[state.actualCourseItemIndex].item_sort = payload.item_sort;
        }

        if (payload.duration !== undefined) {
            state.courseItems[state.actualCourseChapterIndex].items[state.actualCourseItemIndex].duration = payload.duration;
        }
    },

    SET_COURSE_ITEMS(state, payload) {
        state.courseItems = payload;
    },

    SET_COURSE_CHAPTER(state, payload) {

        if (payload.chapter_title !== undefined) {
            state.courseItems[state.actualCourseChapterIndex].chapter_title = payload.chapter_title;
        }

        if (payload.chapter_description !== undefined) {
            state.courseItems[state.actualCourseChapterIndex].chapter_description = payload.chapter_description;
        }

        if (payload.chapter_icon !== undefined) {
            state.courseItems[state.actualCourseChapterIndex].chapter_icon = payload.chapter_icon;
        }

        if (payload.chapter_sort !== undefined) {
            state.courseItems[state.actualCourseChapterIndex].chapter_sort = payload.chapter_sort;
        }

        if (payload.chapter_price !== undefined) {
            state.courseItems[state.actualCourseChapterIndex].chapter_price = payload.chapter_price;
        }

        if (payload.chapter_price_discount !== undefined) {
            state.courseItems[state.actualCourseChapterIndex].chapter_price_discount = payload.chapter_price_discount;
        }

    },

    SET_COURSE_ITEM_MEDIA(state, payload) {

        let course = state.courseItems;

        console.log('Kurs');
        console.log(course);
        console.log(payload);

        console.log(state.actualCourseChapterIndex, state.actualCourseItemIndex);

        let media = course[state.actualCourseChapterIndex].items[state.actualCourseItemIndex];
        console.log('media');
        console.log(media);

        if (media === undefined) return;

        if (media.media === undefined) {
            media.media = [];
        }

        for (var index in media.media) {

            if (media.media[index].md5 == payload.item.md5) {
                console.log('doppelten Eintrag gefunden');
                course[state.actualCourseChapterIndex].items[state.actualCourseItemIndex].media[index] = payload.item;

                Vue.set(state.courseItems, state.actualCourseChapterIndex, course[state.actualCourseChapterIndex]);

                return;
            }

        }

        course[state.actualCourseChapterIndex].items[state.actualCourseItemIndex].media.push(payload.item);

        //console.log(course[state.actualCourseChapterIndex]);

        //state.checklistItems = checklist;
        Vue.set(state.courseItems, state.actualCourseChapterIndex, course[state.actualCourseChapterIndex]);
    },
}

const actions = {

    setActualMainCategoryIndex({commit}, payload) {
        commit('SET_ACTUAL_MAIN_CATEGORY_INDEX', payload);
    },

    setActualSubCategoryIndex({commit}, payload) {
        commit('SET_ACTUAL_SUB_CATEGORY_INDEX', payload);
    },

    setActualItemIndex({commit}, payload) {
        commit('SET_ACTUAL_ITEM_INDEX', payload);
    },

    setActualSidebarArea({commit}, payload) {
        commit('SET_ACTUAL_SIDEBAR_AREA', payload);
    },

    setActualItemId({commit}, payload) {
        commit('SET_ACTUAL_ITEM_ID', payload);
    },

    setProductList({commit}, payload) {
        commit('SET_PRODUCT_LIST', payload);
    },

    addToProductList({commit}, payload) {
        commit('ADD_TO_PRODUCT_LIST', payload);
    },

    setProductTypes({commit}, payload) {
        commit('SET_PRODUCT_TYPES', payload);
    },

    setChecklistItems({commit}, payload) {
        commit('SET_CHECKLIST_ITEMS', payload);
    },

    setMainCategory({commit}, payload) {
        commit('SET_MAIN_CATEGORY', payload);
    },

    setSubCategory({commit}, payload) {
        commit('SET_SUB_CATEGORY', payload);
    },

    setChecklistItem({commit}, payload) {
        commit('SET_CHECKLIST_ITEM', payload);
    },

    setChecklistItemMedia({commit}, payload) {
        commit('SET_CHECKLIST_ITEM_MEDIA', payload);
    },

    setMediaTestData({commit}, payload) {
        commit('SET_MEDIA_TEST_DATA', payload);
    },

    setActualWhitepaperId({commit}, payload) {
        commit('SET_ACTUAL_WHITEPAPER_ID', payload);
    },

    setWhitepaperMedia({commit}, payload) {
        commit('SET_WHITEPAPER_MEDIA', payload);
    },

    setWhitepaperMediaItem({commit}, payload) {
        commit('SET_WHITEPAPER_MEDIA_ITEM', payload);
    },

    setProductMedia({commit}, payload) {
        commit('SET_PRODUCT_MEDIA', payload);
    },

    setProductMediaItem({commit}, payload) {
        commit('SET_PRODUCT_MEDIA_ITEM', payload);
    },

    setProductMediaDescriptionTypes({commit}, payload) {
        commit('SET_PRODUCT_MEDIA_DESCRIPTION_TYPES', payload);
    },

    setMyProductList({commit}, payload) {
        commit('SET_MY_PRODUCT_LIST', payload);
    },

    updateMyProductIsMyProduct({commit}, payload) {
        commit('UPDATE_PRODUCT_IS_MY_PRODUCT', payload);
    },

    setActualCourseChapterIndex({commit}, payload) {
        commit('SET_ACTUAL_COURSE_CHAPTER_INDEX', payload);
    },

    setActualCourseItemIndex({commit}, payload) {
        commit('SET_ACTUAL_COURSE_ITEM_INDEX', payload);
    },

    setActualCourseItemId({commit}, payload) {
        commit('SET_ACTUAL_COURSE_ITEM_ID', payload);
    },

    setActualCourseSidebarArea({commit}, payload) {
        commit('SET_ACTUAL_COURSE_SIDEBAR_AREA', payload);
    },

    setCourseItem({commit}, payload) {
        commit('SET_COURSE_ITEM', payload);
    },

    setCourseItems({commit}, payload) {
        commit('SET_COURSE_ITEMS', payload);
    },

    setCourseChapter({commit}, payload) {
        commit('SET_COURSE_CHAPTER', payload);
    },

    setCourseItemMedia({commit}, payload) {
        commit('SET_COURSE_ITEM_MEDIA', payload);
    },
}

const getters = {

    getMyProductList() {
        return state.myProductList;
    },

    getMyPurchasedProducts() {

        const products = state.myProductList;
        return Array.from(products).filter((item) =>  item.is_my_product ==1);
 
    },
    getProductById: (state) => (id = -1) => {
        
        const list = state.myProductList;
        const product =  Array.from(list).filter((item) =>  {
            return (item.product_id === id)
        });

       return product[0] || {};
    },

    getNotPurchasedProducts() {
        const products = state.myProductList;
        return Array.from(products).filter((item) =>  item.is_my_product == 0);
    },

    getProductList() {
        return state.productList;
    },

    getProductTypes() {
        return state.productTypes;
    },

    getChecklistItems() {

        return state.checklistItems;
    },

    getChecklistItemMain() {

        if (state.checklistItems[state.actualMainCategoryIndex] === undefined) {
            return [];
        }

        return state.checklistItems[state.actualMainCategoryIndex];
    },

    getChecklistItemSub() {

        return state.checklistItems[state.actualMainCategoryIndex].sub_categories[state.actualSubCategoryIndex];
    },

    getChecklistItem() {

        //if (key !== '') return state.checklistItems[state.actualMainCategoryIndex].sub_categories[state.actualSubCategoryIndex].items[state.actualItemIndex][key];

        if (state.checklistItems[state.actualMainCategoryIndex] === undefined) {
            return [];
        }

        return state.checklistItems[state.actualMainCategoryIndex].sub_categories[state.actualSubCategoryIndex].items[state.actualItemIndex];
    },

    getActualMainCategoryIndex() {

        return state.actualMainCategoryIndex;
    },

    getActualSubCategoryIndex() {

        return state.actualSubCategoryIndex;
    },

    getActualItemIndex() {

        return state.actualItemIndex;
    },

    getActualSidebarArea() {

        return state.actualSidebarArea;
    },

    getActualItemId() {

        return state.actualItemId;
    },

    getActualWhitepaperId() {

        return state.actualWhitepaperId;
    },

    getWhitepaperMedia() {

        return state.whitepaperMedia;
    },

    getProductMedia() {

        return state.productMedia;
    },

    getProductMediaDescriptionTypes() {

        return state.productMediaDescriptionTypes;
    },

    getActualCourseChapterIndex() {

        return state.actualCourseChapterIndex;
    },

    getActualCourseItemIndex() {

        return state.actualCourseItemIndex;
    },

    getActualCourseItemId() {

        return state.actualCourseItemId;
    },

    getActualCourseSidebarArea() {

        return state.actualCourseSidebarArea;
    },

    getCourseItems() {

        return state.courseItems;
    },

    getCourseItem() {

        if (state.courseItems[state.actualCourseChapterIndex] === undefined) {
            return [];
        }

        return state.courseItems[state.actualCourseChapterIndex].items[state.actualCourseItemIndex];
    },

    getCourseItemChapter() {

        if (state.courseItems[state.actualCourseChapterIndex] === undefined) {
            return [];
        }

        return state.courseItems[state.actualCourseChapterIndex];
    },

}

const product = {
    state,
    mutations,
    actions,
    getters

}

export default product;