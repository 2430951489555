<template>
    <div  class="wl-sticky" ref="stickyelement"   :style="cssStyles">
        <div>            
            <slot />
        </div>
    </div>
</template>

<script>

export default {
    name: "hocSticky",
    data() {
    return{
      cssStyles: '',
      scrollY: null,
      offsetTop: 0,
      isSticky: false,
      dims: {},
      timeoutid: 0
    }
  },
  props: {
    offset: {
      type: String,
      default: '0'
    }

  },
  mounted() {
   
      const {stickyelement} = this.$refs;
      this.dims = stickyelement.getBoundingClientRect();
      //this.cssStyles = `margin-left: ${ -Math.abs(this.dims.width / 2 + 15)}px;`;
      
  },
  
}
</script>
