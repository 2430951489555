<template>
  <b-container fluid="xxl">

    <b-row class="mb-6" v-if="getNotPurchasedProducts.length > 0">
      <b-col cols="12">
        <hocSlider :show-button="false" :no-padding="true" :reset="emitChanges" :small-layout="true"  variant="primary" :title="$t('ALSO_INTERESTING_FOR_YOU')" :cardicon="offerIcon" slider-class="wl-slider__slider-default">
          <template #slideitems>
            <CardSliderItem v-for="(product, product_index) in getNotPurchasedProducts" :key="product_index"
            :product="product"
            :product-id="product.product_id  | tostring"
            :product-title="product.product.product_name" 
            :product-type="product.product.product_type"
            :product-description="product.product.product_description_short"

            :product-cta-link="
              (product.product.product_type == 'Whitepaper')
                ? product['whitepaper'].media[0].url
                : ''"
            :product-btn-label="handleSliderProductBtnLabel(product)"
              v-on:submit="handleSliderProductCardEvent"
            
          />
          </template>
          
          <template #slideimages="{ slide }">
          <CardSliderImage :slide="slide" v-for="(product, product_index) in getNotPurchasedProducts" :key="product_index" 
            :id="product_index" 
            :product-image="$utils.getMediaImg(1, product.media, 'product_media_description_type_id')"
          />
          </template>
        </hocSlider>
      </b-col>
    </b-row>



      <b-row>
        <b-col cols="12">
          <h3>{{ $t('NAV_LINK_TEXT_MY_PRODUCTS') }}</h3>
          <p class="mb-5" v-if="getMyPurchasedProducts.length == 0" v-html="$t('NO_PRODUCTS_IN_YOUR_LIST')"></p>
      </b-col>
    </b-row>
    
    

    <b-row>

      <!-- item -->
      <b-col
          v-for="(product, product_index)  in getMyPurchasedProducts"
          :key="product_index"
          cols="12" md="6" xl="4" xxl="3" 
          class="mt-4 mb-3"
      >
        <ProductCard
          :no-padding="true"
          :product="product"
          :product-id="product.product_id  | tostring"
          :product-title="product.product.product_name" 
          :product-type="product.product.product_type"
          :product-image="$utils.getMediaImg(3, product.media, 'product_media_description_type_id')"
          :product-is-my-product="product.is_my_product"
          :product-purchased="$t('PURCHASED_DATE') + product.sale_date_formatted"
          :badge-text="badgeTextHandler(product)"

          :product-cta-link="productCardButtonCta(product)"
         
          :product-btn-label="productCardButtonLabelHandler(product)"

          :product-description="product.product.product_description_short"
          v-on:submit="handleFreeProductCardEvent"
          :small-layout="true"
        />


      </b-col>

    </b-row>

    <b-modal size="xl" ref="promotion-modal" hide-footer :title="promotionModalTitle">
      <div class="d-block text-left">
        <p v-html="promotionModalContent"></p>
      </div>
    </b-modal>
     
  </b-container>
</template>

<script>
//import * as CONFIG from '@/wl-config/wl-app-config';
const CONFIG =  require(`@/wl-config/${process.env.VUE_APP_WL_FOLDER}/wl-app-config`)
import {mapActions, mapGetters, mapMutations} from "vuex";
import axios from "axios";
import hocSlider from '@/components/hoc/hocSlider.vue';
import CardSliderItem from '@/components/ProductCardSlider/CardSliderItem.vue';
import CardSliderImage from '@/components/ProductCardSlider/CardSliderImage.vue';
import ProductCard from '@/components/ProductCard.vue';
//import Vue from 'vue';

export default {
  name: "areaMyProducts",
  components: {
    hocSlider,
    CardSliderItem,
    CardSliderImage,
    ProductCard
  },
  data() {
    return {
      API_URL: process.env.VUE_APP_API, 
      emitChanges: false,
      offerIcon: CONFIG.WLCONFG.ICON_OFFER,
      promotionModalTitle: '',
      promotionModalContent: '',
    }
  },
  mounted() {
    this.loadMyProductList();
    
  },
  computed: {
    ...mapGetters([
      'getMyPurchasedProducts',
      'getNotPurchasedProducts',
      'getTokenParamWithTokenMd5',
    ]),
  },
  methods: {
    ...mapMutations([]),
    ...mapActions([
        'setMyProductList',
        'updateMyProductIsMyProduct',
    ]),
    ...mapGetters([]),

    async loadMyProductList() {

      //todo: local development
      var cookie = this.$store.getters.getToken;

      const response = await axios.post(
          this.API_URL,
          {
            type: 'get_myproducts_list',
            cookie: cookie,
          }
      );

      if (!response.data.error) {
        this.$store.dispatch('setMyProductList', response.data);

      }

    },
    handleSliderProductBtnLabel(product) {

      //console.log(product);

      if(!product || !("product" in product) ) {
        return;
      }

      //window.open(data.url,'_blank');

      var label = '';

      switch(product.product.product_type) {
        case 'Whitepaper':
          label = this.$t('FREE_OF_CHARGE_DOWNLOAD');
          break;

        case 'Checkliste':
          if (product.product.product_price > 0) {
            /*
            label = '';
            if (product.product.product_pseudoprice > 0) {
              label = "<s>" + Vue.utils.formatPrice((product.product.product_pseudoprice)) + "</s>";
            }
            label += Vue.utils.formatPrice((product.product.product_price));

             */
            label = this.$t('MORE_DETAILS');
          } else {
            label = this.$t('FREE_OF_CHARGE_ACCESS');
          }
          break;

        case 'Onlinekurs':
          if (product.product.product_price > 0) {
            /*
            label = '';
            if (product.product.product_pseudoprice > 0) {
              label = "<s>" + Vue.utils.formatPrice((product.product.product_pseudoprice)) + "</s>";
            }
            label += Vue.utils.formatPrice((product.product.product_price));

             */
            label = this.$t('MORE_DETAILS');
          } else {
            label = this.$t('GO_TO_LEARNING_CONTENT_FREE');
          }
          break;

        case 'Support':
          label = this.$t('MORE_DETAILS');
          break;

        case 'Mastermind':
          label = this.$t('MORE_DETAILS');
          break;


      }

      return label;


    },
    async handleSliderProductCardEvent(data) {
      if(!data || !("type" in data) ) {
        return;
      }

      var url = '';
      var purl = '';
      //console.log(data);

      switch(data.type) {

        case 'Whitepaper':
          await this.$root.$emit('track', {source: null, target: data.url, button: data.type + ': ' + data.product.product.product_name});
          this.trackSale(data.id, data.url);
          break;

        case 'Checkliste':
          if (("product" in data)
              && data.product.product.product_price > 0
          ) {
            await this.$root.$emit('track', {source: null, target: data.product.product.product_url, button: data.type + ': ' + data.product.product.product_name});
            //zur Kaufseite
            purl = this.$utils.checkIfUrlAndAddParam(data.product.product.product_url, this.getTokenParamWithTokenMd5);
            window.open(purl,'_blank');
          } else {
            url = '/myChecklistProjects/' + data.id;
            await this.$root.$emit('track', {source: null, target: url, button: data.type + ': ' + data.product.product.product_name});
            //Gratisliste erwerben
            this.trackSaleChecklist(data.id, url);
          }
          break;

        case 'Onlinekurs':
          if (("product" in data)
              && data.product.product.product_price > 0
          ) {
            await this.$root.$emit('track', {source: null, target: data.product.product.product_url, button: data.type + ': ' + data.product.product.product_name});
            //zur Kaufseite
            purl = this.$utils.checkIfUrlAndAddParam(data.product.product.product_url, this.getTokenParamWithTokenMd5);
            window.open(purl,'_blank');
          } else {
            url = '/academylesson/' + data.id;
            await this.$root.$emit('track', {source: null, target: url, button: data.type + ': ' + data.product.product.product_name});
            //Gratis erwerben
            this.trackSaleCourse(data.id, url);
          }
          break;

        case 'Support':
        case 'Mastermind':
          /*
          if (("product" in data)
              && data.product.product.product_price > 0
          ) {
            await this.$root.$emit('track', {source: null, target: data.product.product.product_url, button: data.type + ': ' + data.product.product.product_name});
            //zur Kaufseite
            purl = this.$utils.checkIfUrlAndAddParam(data.product.product.product_url, this.getTokenParamWithTokenMd5);
            window.open(purl,'_blank');
          }

           */

          if (("product" in data)) {

            if (data.product.product.product_is_promotionpage) {

              await this.$root.$emit('track', {source: null, target: 'promotionpage', button: data.type + ': ' + data.product.product.product_name});

              await this.promotion(data.product.product.product_id);

            } else if (data.product.product.product_url != '' && data.product.product.product_url != null) {

              await this.$root.$emit('track', {source: null, target: data.product.product.product_url, button: data.type + ': ' + data.product.product.product_name});
              //zur Kaufseite
              purl = this.$utils.checkIfUrlAndAddParam(data.product.product.product_url, this.getTokenParamWithTokenMd5);
              window.open(purl,'_blank');

            }
          }
          break;
      }
        
        
    },
    async promotion(product_id) {

      //console.log(item);

      //todo: local development
      var cookie = this.$store.getters.getToken;

      const response = await axios.post(
          this.API_URL,
          {
            type: 'get_preview_promotion',
            product_id: product_id,
            cookie: cookie
          }
      );

      //console.log(response.data);

      this.promotionModalTitle = response.data.product_promotion_title;
      this.promotionModalContent = response.data.product_promotion_description;

      this.$refs['promotion-modal'].show();
    },
    async handleFreeProductCardEvent(data) {
      if(!data || !("type" in data) ) return;

      switch(data.type) {
        case 'Whitepaper':
          await this.$root.$emit('track', {source: null, target: data.url, button: data.type + ': ' + data.product.product.product_name});
          window.location.href = data.url;
          break;

        case 'Checkliste':
          await this.$root.$emit('track', {source: null, target: data.url, button: data.type + ': ' + data.product.product.product_name});
          this.$router.push(data.url);
          break;

        case 'Onlinekurs':
          //console.log(data);
          await this.$root.$emit('track', {source: null, target: data.url, button: data.type + ': ' + data.product.product.product_name});
          //Gratis erwerben
          this.trackSaleCourse(data.product.product_id, data.url);
          break;
      }
    },
    async trackSale(product_id, url) {

      //todo: local development
      var cookie = this.$store.getters.getToken;

      await axios.post(
          this.API_URL,
          {
            type: 'track_sale',
            cookie: cookie,
            product_id: product_id,
          }
      );

      this.$store.dispatch('updateMyProductIsMyProduct', product_id);
      this.emitChanges = true;
      //console.log(url);
      var purl = this.$utils.checkIfUrlAndAddParam(url, this.getTokenParamWithTokenMd5);
      window.location = purl;


    },

    async trackSaleChecklist(product_id, url) {

      //todo: local development
      var cookie = this.$store.getters.getToken;

      await axios.post(
          this.API_URL,
          {
            type: 'track_sale_checklist',
            cookie: cookie,
            product_id: product_id,
          }
      );

      this.$store.dispatch('updateMyProductIsMyProduct', product_id);
      this.$router.push(url);

    },

    async trackSaleCourse(product_id, url) {

      //todo: local development
      var cookie = this.$store.getters.getToken;

      await axios.post(
          this.API_URL,
          {
            type: 'track_sale_course',
            cookie: cookie,
            product_id: product_id,
          }
      );

      this.$router.push(url);

    },

    badgeTextHandler(product) {

      //console.log(product);

      var label = '';

      switch(product.product.product_type) {

        case 'Checkliste':

          label = this.$t('PURCHASED_CHECKLIST');
          break;

        case 'Whitepaper':

          label = this.$t('PURCHASED_WHITEPAPER');
          break;

        case 'Onlinekurs':

          label = this.$t('PURCHASED_LEARNING_CONTENT');
          break;

        case 'Mastermind':

          label = this.$t('PURCHASED_MASTERMIND');
          break;

        case 'Support':

          label = this.$t('PURCHASED_SUPPORT');
          break;

      }

      return label;

    },

    productCardButtonLabelHandler(product) {

      var label = '';

      switch(product.product.product_type) {

        case 'Checkliste':

          label = this.$t('TO_THE_CHECKLIST');
          break;

        case 'Whitepaper':

          label = this.$t('FREE_OF_CHARGE_DOWNLOAD');
          break;

        case 'Onlinekurs':

          label = this.$t('GO_TO_LEARNING_CONTENT');
          break;

      }

      return label;


    },

    productCardButtonCta(product) {

      var out = '';

      switch(product.product.product_type) {

        case 'Checkliste':

          out = `/myChecklistProjects/${product.product_id}`;
          break;

        case 'Whitepaper':

          out = product['whitepaper'].media[0].url;
          break;

        case 'Onlinekurs':

          out = `/academylesson/${product.product_id}`;
          break;

      }

      return out;


    },
  }
}
</script>

