<template>
    <b-card tag="div" class=" flex-1 wl-card w-100" :class="[`bg-${variant}`, (noPadding) ? 'wl-card--no-padding' : 'p-2', (shadow) ? 'shadow' : '' ]" >
    
        <div class="wl-card__header mb-5 pt-4 d-flex align-items-center"  v-if="cardicon || title || insertLogo">
            <template v-if="insertLogo">
                <div class="wl-card__icon" :class="{ 'small': smallLayout }">
                    <b-img :src="getLogo()"  fluid />
                </div>
            </template>

            <template v-else>
                <div class="wl-card__icon" v-if="cardicon"  :class="{ 'small': smallLayout }">
                    <font-awesome-icon :icon="cardicon" />
                </div>
            </template>
            
            <div>
                <b-card-sub-title v-if="subtitle">
                    {{ subtitle }}
                </b-card-sub-title> 
                <b-card-title v-if="title">
                    <span :class="{ 'small': smallLayout }">{{ title }}</span>
                </b-card-title>
            </div>
           
        </div>
        <slot></slot>   
    </b-card>
</template>

<script>


//import * as CONFIG from '@/wl-config/wl-app-config';

const CONFIG =  require(`@/wl-config/${process.env.VUE_APP_WL_FOLDER}/wl-app-config`)

export default {
    name: "hocCard",
    data() {
        return {
            wlAppConstants: CONFIG.WLCONFG
        }
    },
    props: {
        cardicon: String,
        title: String,
        subtitle: String,
        shadow: {
            type: Boolean,
            default: true
        },
        insertLogo: {
            type: Boolean,
            default: false
        },
        smallLayout: {
            type: Boolean,
            default: false   
        },
        noPadding: {
            type:Boolean,
            default:false
        },
        variant: {
            type: String,
            default: 'white'
        }
    },
    methods: {
        getLogo() {   
            return require(`@/wl-config/${process.env.VUE_APP_WL_FOLDER}/assets/images/${this.wlAppConstants.LOGO_SIGNET}`);
        }

    }

}
</script>
