import Vue from 'vue'
import Vuex from 'vuex'

import userList from "@/store/userList";
import user from "@/store/user";
import product from "@/store/product";
import academy from "@/store/videos";
import design from "@/store/design";

Vue.use(Vuex)

export default new Vuex.Store({

    modules: {
        userList,
        user,
        product,
        academy,
        design
    }
})