<template>
    <b-form class="wl-form__registration">

      <b-form-group label="" label-for="firstname" class="mb-3">
        <b-form-input
            :class="(isFirstVisit) ? 'wl-form--clean' : ''"
            @input="handleFirstVisit"
            id="firstname"
            type="text"
            :placeholder="$t('FORM_FIRSTNAME') + '*'"
            required
            v-model.trim="formData.firstname"
            :state="validation('firstname')"
        ></b-form-input>
        <b-form-invalid-feedback :state="validation('firstname')">
          {{ $t('VALID_SURENAME_REQUIRED') }}
        </b-form-invalid-feedback>
      </b-form-group>

      <b-form-group label="" label-for="telephone" class="mb-3">
        <b-form-input
            :class="(isFirstVisit) ? 'wl-form--clean' : ''"
            @input="handleFirstVisit"
            id="telephone"
            type="text"
            :placeholder="$t('FORM_TELEPHONE') + '*'"
            required
            v-model.trim="formData.telephone"
            :state="validation('telephone')"
        ></b-form-input>
        <b-form-invalid-feedback :state="validation('telephone')">
          {{ $t('VALID_TELEPHONE_NUMBER_REQUIRED') }}
        </b-form-invalid-feedback>
      </b-form-group>

      <b-form-group label="" label-for="email" class="mb-3">
        <b-form-input
            :class="(isFirstVisit) ? 'wl-form--clean' : ''"
            @input="handleFirstVisit"
            id="email"
            type="email"
            :placeholder="$t('FORM_EMAIL') + '*'"
            required
            v-model.trim="formData.email"
            :state="validation('email')"
        ></b-form-input>
        <b-form-invalid-feedback :state="validation('email')">
          {{ $t('VALID_EMAIL_ADDRESS_REQUIRED') }}
        </b-form-invalid-feedback>
      </b-form-group>

      <b-form-group>
        <div class="d-flex">
          <div class="toggle-pill-bw mr-3" @click="handleFirstVisit">
            <input
                type="checkbox"
                required=""
                name="datasecurity"
                id="agreeanalysis"
                value="1"
                v-model="formData.datasecurity"
                :state="validation('datasecurity')"
            >
            <label for="agreeanalysis"></label>

          </div>
          <div>
            <small>Ich habe die Einverständnis-Erklärung gelesen und bin einverstanden **</small>
            
          </div>
        </div>
        <b-form-invalid-feedback :state="validation('datasecurity')">
          {{ $t('AGREE_PRIVACY_POLICY') }}
        </b-form-invalid-feedback>
      </b-form-group>

      <b-form-group class="text-center mt-5 mb-5">
        <b-button :disabled="!isFormError() || showEmailExistAlert || isFirstVisit"  type="button" variant="cta-secondary"  @click="registration()">
          {{ $t('REGISTER_BUTTON') }}
        </b-button>

      </b-form-group>

      <b-form-group>

        <p><small>** Einverständnis-Erklärung: Die Informationen zum <b-link href="#" class="text-light font-bold text-underline" v-b-modal="'modal-policy'">Datenschutz</b-link> und zu den <b-link href="#" class="text-light font-bold text-underline" v-b-modal="'modal-policy'">AGB</b-link> habe ich zur Kenntnis genommen und stimme diesen hiermit zu. Ich akzeptiere, dass meine personenbezogenen Daten für Werbezwecke durch die {{ wlAppConstants.COMPANY_NAME }} genutzt werden können.</small></p>
        <p><small>Die Ansprache erfolgt per E-Mail. Die Datennutzung durch die {{ wlAppConstants.COMPANY_NAME }}, {{ wlAppConstants.COMPANY_ADDRESS }}, ist davon umfasst. Der Newsletter ist jederzeit kündbar.</small></p>

      </b-form-group>

      <b-modal id="modal-policy" size="lg"  :centered="true" :hide-footer="true" :title="$t('POLICY_TERMS_TITLE')">
        <viewPolicy />
      </b-modal>
    </b-form>
</template>


<script>

import axios from 'axios';
import viewPolicy from '@/views/viewPolicy';
const CONFIG =  require(`@/wl-config/${process.env.VUE_APP_WL_FOLDER}/wl-app-config`);

export default {
  name: "areaRegistration",
  components: {
    viewPolicy
  },
  data() {
    return {
      API_URL: process.env.VUE_APP_API,
      autoHideToast: 5000,
      formDataError: {},
      formData: {
        email: '',
        firstname: '',
        telephone: '',
        datasecurity: 0,
      },
      showEmailExistAlert: false,
      emailActualCheck: '',
      isFirstVisit: true,
      wlAppConstants: CONFIG.WLCONFG
    }
  },
  methods: {
    handleFirstVisit() {
      this.isFirstVisit = false;
    },
    async registration() {

      await this.$root.$emit('track', {source: null, target: null, button: 'registrationSendForm'});

      const response = await axios.post(
          this.API_URL,
          {
            type: 'registration',
            firstname: this.formData.firstname,
            telephone: this.formData.telephone,
            email: this.formData.email,
          }
      );

     if(response.data === 1) {
        this.$bvToast.toast([this.$t("SUCCESS_REGISTRATION_RESPONSE_TEXT")], {
              title: [this.$t("SUCCESS_TITLE_DEFAULT")],
              solid: true,
              toaster: "b-toaster-top-center",
              variant: "success",
              noCloseButton: true,
              autoHideDelay: this.autoHideToast
          });
     } else {
        this.$bvToast.toast([this.$t("EMAIL_EXISTS_RESPONSE_TEXT")], {
            title: [this.$t("ERROR_TITLE_DEFAULT")],
            solid: true,
            toaster: "b-toaster-top-center",
            variant: "danger",
            noCloseButton: true,
            autoHideDelay: this.autoHideToast
        });
     }

    },
    validation(type) {

      /*console.log(type);
      console.log(this.formData[type]);*/

      var ret = false;
      var data = '';
      if (this.formData[type] !== null) {
        data = this.formData[type];
      }
      //console.log(data);

      switch(type) {

        case 'email':

          var reg = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/; /* eslint-disable-line */

          if (reg.test(data)) {

            ret = true;

            if (this.email != data) {
              this.checkEmail(data);

            }

          } else {
            ret = false;
          }
          break;

        case 'firstname':

          ret = true;
          if (data.length < 2) {
            ret = false;
          }

          break;

        case 'telephone':

          var reg = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,10}$/im; /* eslint-disable-line */

          if (reg.test(data)) {
            ret = true;
          } else {
            ret = false;
          }
          break;

        case 'datasecurity':
          if (!data) {
            ret = false;
          } else {
            ret = true;
          }
          break;

        default:
          ret = true;
          break;

      }


      this.formDataError[type] = ret;
      if(this.isFirstVisit) return true;
      this.isFormError();
      return ret;

    },
    isFormError() {

      var form = this.formDataError;

      
      if (form.length === 0) return true;

      for (const key in form) {
        //console.log(key);
        if (!form[key]) {
          //console.log(false);
          return false;
        }
      }

      return true;

    },
    async checkEmail(email) {

      if (this.emailActualCheck == email) return;

      this.emailActualCheck = email;

      const response = await axios.post(
          this.API_URL,
          {
            type: 'check_email',
            email: email,
          }
      );

      this.showEmailExistAlert = false;
      if (response.data) {
        this.showEmailExistAlert = true;

        this.$bvToast.toast([this.$t("EMAIL_EXISTS_RESPONSE_TEXT")], {
                title: [this.$t("ERROR_TITLE_DEFAULT")],
                solid: true,
                toaster: "b-toaster-top-center",
                variant: "danger",
                noCloseButton: true,
                autoHideDelay: this.autoHideToast
            });
      }

      return response.data;

    },
  }
}
</script>

<style scoped>

</style>