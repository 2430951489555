<template>
     <b-container fluid="xxl">
        <b-row class="mb-4">
            <b-col cols="12" >
                <b-button
                type="button"
                variant="cta"
                class="button-cta rounded-circle icon"
                :to="'/blogarticles'"
                ><font-awesome-icon icon="fa-solid fa-caret-left" /></b-button>

            </b-col>

        </b-row>

        <b-row>
            <b-col cols="12">
                <h6 class="font-light">{{ $t('BLOG') }}</h6> 
                <h3>{{articleTitle}}</h3>
                <img v-if="articleImage" class="rounded img-fluid mb-3 mt-4 d-block" :src="articleImage"/>
                <p class="mt-4" v-html="articleContent"></p>
                <b-button
                type="button"
                variant="cta"
                v-b-modal.modal-additional-information
                class="button-cta"
                @click="handleModalEvent"
                >{{ $t('MORE_DETAILS') }}</b-button>

                <b-modal size="xl" id="modal-additional-information" hide-footer :title="$t('MORE_DETAILS')">
                    <p>Modal Content</p>
                </b-modal>
            </b-col>
        </b-row>

      
     </b-container>
</template>


<script>
    import {mapActions, mapGetters} from "vuex";
    import axios from "axios";
    export default {
        name: "areaBlogArticle",
        data () {
            return {
                articledata: []
            }
        },
        components: {
        },
        computed: {
        ...mapGetters( {
             
            }),
            articleTitle: {
                get() {
                    return this.articledata.title
                }
            },
            articleContent: {
                get() {
                    return this.articledata.content
                }
            },
            articleImage: {
                get() {
                    return this.articledata.image
                }
            }
           

        },
        mounted() {
            this.loadBlogData();
            
        },
        updated(){
            this.loadBlogData();
            
        },
        methods: {
          ...mapActions([
          ]),

          handleModalEvent() {
            console.log('Modal is open')
          },

          async loadBlogData() {
            const response = await axios.get('/staticMockData/blogarticles.json');
             const [first]= response.data.articles.filter( (article) =>{
                return article.article_id == this.$route.params.article_id;
            });

            this.articledata = first;
          },

        }
    }
</script>

<style scoped>

</style>