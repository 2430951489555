<template>
<b-card tag="div" class="shadow h-100 wl-card wl-card__slider w-100" :class="[`bg-${variant}`, (noPadding) ? 'wl-card--no-padding' : 'p-2' ]">    
    <b-container fluid="xxl" class="h-100">
        <b-row class="position-static h-100">
            <b-col cols="12" :xxl="(silderimagesActive) ? 5 : 12" class="order-xxl-0 order-1 position-static">
                <div class="p-2">
                    <div class="wl-card__slider__inner"  :class="{ 'wl-card__slider__inner--is-normalizing': isNormalizing }">
                         <div class="wl-card__header mb-5 pt-4 d-flex align-items-center"  v-if="cardicon || title">

                            <div class="wl-card__icon" v-if="cardicon"  :class="{ 'small': smallLayout, 'wl-card__icon--mute': headlineMuted }">
                                <font-awesome-icon :icon="cardicon" />
                            </div>
                            <div>
                                <h6 v-if="subtitle" class="card-subtitle " :class="{ 'card-subtitle--mute': headlineMuted }">
                                    {{ subtitle }}
                                </h6>
                                <h4 v-if="title" class="card-title " :class="{ 'card-title--mute': headlineMuted }">
                                    <span :class="{ 'small': smallLayout }">{{ title }}</span>
                                </h4>
                            </div>
                        </div>

                        <b-carousel ref="bsslider"
                            :id="`carousel-${id}`"
                            v-model="slide"
                            :interval="intervalset"
                            indicators
                            :no-hover-pause="true"
                            @sliding-start="onSlideStart"
                            class="wl-slider" :class="sliderClass"
                            >
                            <slot name="slideitems"  />

                        </b-carousel>
                    </div>
                   
                </div>
                
            </b-col>
            <b-col cols="12" xxl="7" class="order-xxl-1 order-0  p-0 position-relative wl-card__slider__images" :class="(slide) ? '' : 'wl-card__slider__images--empty'" v-if="silderimagesActive">
			
                <slot name="slideimages" :slide="slide" />
                
                <div class="wl-card__slider__button" v-if="showButton">
                    <b-button
                        to="/myProducts"
                        type="button"
                        variant="cta"
                        class="button-cta"
                        @click="$root.$emit('track', {source: null, target: null, button: $t('PRODUCT_OVERVIEW')})"
                    >
                        {{ $t('PRODUCT_OVERVIEW') }} 
                       
                    </b-button>
                    
                </div>
            </b-col>
        </b-row>
    </b-container>
    </b-card>
</template>


<script>
import hocCard from '@/components/hoc/hocCard';
import Vue from 'vue'
export default {
  name: "hocSlider",
  mixins: [hocCard],
  data() {
    return {
        intervalset: this.interval,
        slide: 0,
        intervalID:0, 
        resetSlider: this.reset,
        sliding: null,
        isNormalizing: true,
        silderimagesActive: {
            type: Boolean,
            default: false
        }
    }
  },
  props: {
    reset:{
        type: Boolean,
        default: false
    },
    sliderClass: {
        type: String,
        default: ''
    },
    headlineMuted: {
        type: Boolean,
        default: true
    },
    id: {
        type: String,
        default: () => Vue.utils.generateRandomHash(10)
    },
    interval: {
        type: Number,
        default: 6000
    },
    showButton: {
        type: Boolean,
        default: true
    }

  },
  mounted() {
    this.sliderImagesSlotIsUsed()
    
    this.$nextTick(() => {
       this.setHeights()
    });
    window.addEventListener('resize', () => {
        clearTimeout(this.intervalID)
        this.intervalID = setTimeout( () => {
                this.setHeights()
        }, 50)
        
        
    });
  },
  methods: {
      onSlideStart(slide) {
        //this.$root.$emit('track', {source: null, target: null, button: 'carouselSliderDashboardAuto' + slide});
        this.sliding = true
        this.slide = slide;
      },
      sliderImagesSlotIsUsed() {

         try {
			
            this.silderimagesActive = (this.$scopedSlots.slideimages({}));
         } catch(e) {
            this.silderimagesActive = false;
         }
       },
       setHeights() {
            const slides = this.$el.querySelectorAll('.carousel-item');
         
            let array = [];
            [...slides].forEach((slide)  => {

                slide.style.height = 'auto';
            });

            [...slides].forEach((slide)  => {

                array = [...array, slide.getBoundingClientRect().height]
            });
            const maxvalue = this.$utils.getMaxValue(array); 

            [...slides].forEach((slide)  => {
                slide.style.height = `${maxvalue + 40}px`;
            });

            this.isNormalizing = false;
       }
      
    }, 
    watch: {
        // whenever question changes, this function will run
        reset(val, oldVal) {
            if (val != oldVal) {
                const {bsslider} = this.$refs;
                bsslider.setSlide(0);
            }
        }
  }
}
</script>
