import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

import areaUserList from "@/components/areaUserList";
import areaUserEdit from "@/components/areaUserEdit";
import areaRecording from "@/components/areaRecording";
import areaRecordingWeeks from "@/components/areaRecordingWeeks";
import areaRecordingMonths from "@/components/areaRecordingMonths";
import areaStatistic from "@/components/areaStatistic";
import areaAdminProductList from "@/components/areaAdminProductList";
import areaProductEdit from "@/components/areaProductEdit";
import areaAcademyVideo from '@/components/areaAcademyVideo';
import areaAcademyVideoList from '@/components/areaAcademyVideoList' 
import areaBlogList from '@/components/areaBlogList';
import areaBlogArticle from '@/components/areaBlogArticle';
import areaAiTools from "@/components/areaAiTools.vue";
import areaTranscriber from "@/components/areaTranscriber.vue";
//todo: Design Editor
//import areaDesignEditor from '@/components/areaDesignEditor.vue';


//import { mapGetters } from 'vuex';
import store from './store'
import areaMyProducts from "@/components/areaMyProducts";
import areaMyChecklistProjects from "@/components/areaMyChecklistProjects";
import areaMyChecklistOverview from "@/components/areaMyChecklistOverview";
import areaCompanyList from "@/components/areaCompanyList";
import areaError404 from "@/components/areaError404";
import areaDanke from "@/components/areaDanke.vue";

import viewLoginOrSignup from '@/views/viewLoginOrSignup';
import viewPWActivation from '@/views/viewPWActivation';
import viewDashboard from '@/views/viewDashboard';

const CONFIG =  require(`@/wl-config/${process.env.VUE_APP_WL_FOLDER}/wl-app-config`)

const routes = [
    {
        path: '/',
        alias: '/login',
        name: 'login',        
        component: viewLoginOrSignup,     
        meta: {
            requiresAuth: false,
            layout: 'LoginLayout'
        }
    },
    {
        path: '/passwordForgottenActivation/:user_key',
        name: 'password-forgotten-activation',
        component: viewPWActivation,
        props: true,
        meta: {
            requiresAuth: false,
            layout: 'LoginLayout'
        },
    },
    {
        path: '/userList',
        name: 'user-list',
        component: areaUserList,
        meta: {
            requiresAuth: true,
            layout: 'DefaultOneColumnLayout'
        }
    },
    {
        path: '/userEdit/:user_id',
        name: 'user-edit-detail',
        component: areaUserEdit,
        props: true,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/userEdit',
        name: 'user-edit',
        component: areaUserEdit,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/recording',
        name: 'recording',
        component: areaRecording,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/recordingWeeks',
        name: 'recording-weeks',
        component: areaRecordingWeeks,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/recordingMonths',
        name: 'recording-months',
        component: areaRecordingMonths,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/statistic',
        name: 'statistic',
        component: areaStatistic,
        meta: {
            requiresAuth: true,
            layout: 'DefaultLayout',
            sidebar: 'viewStatisticHints',
            showSidebar: CONFIG.WLCONFG.SHOW_STATISTIC_SIDEBAR
            
        }
    },
    {
        path: '/adminProductList',
        name: 'admin-product-list',
        component: areaAdminProductList,
        meta: {
            requiresAuth: true,
            layout: 'DefaultOneColumnLayout'
        }
    },
    /*
    {
        path: '/adminDesignEditor',
        name: 'admin-design-editor',
        component: areaDesignEditor,
        meta: {
            requiresAuth: true,
            layout: 'DefaultOneColumnLayout'
        }
    },

     */
    
    {
        path: '/productEdit/:product_id',
        name: 'edit-product-detail',
        component: areaProductEdit,
        props: true,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/productEdit',
        name: 'edit-product',
        component: areaProductEdit,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/myProducts',
        name: 'my-products',
        component: areaMyProducts,
        meta: {
            requiresAuth: true,
            layout: 'DefaultLayout',
            sidebar: 'viewProductRecommendation',
            showSidebar: CONFIG.WLCONFG.SHOW_PRODUCT_SIDEBAR
        }
    },
    {
        path: '/myChecklistProjects/:product_id',
        name: 'my-checklist-projects',
        component: areaMyChecklistProjects,
        props: true,
        meta: {
            requiresAuth: true,
            layout: 'DefaultLayout',
            sidebar: 'viewProductRecommendation',
            showSidebar: CONFIG.WLCONFG.SHOW_CHECKLIST_SIDEBAR
        }
    },     
    {
        path: '/academylessons',
        name: 'academy-lessons',
        component: areaAcademyVideoList,
        meta: {
            requiresAuth: true,
            layout: 'DefaultLayout',
            sidebar: 'viewTasks',
            showSidebar: CONFIG.WLCONFG.SHOW_ACADEMY_SIDEBAR
        }
    },{
        path: '/academylesson/:video_id',
        name: 'academy-lesson',
        component: areaAcademyVideo,
        meta: {
            requiresAuth: true,
            layout: 'DefaultLayout',
            sidebar: 'viewLessons',
            showSidebar: CONFIG.WLCONFG.SHOW_ACADEMY_LESSON_SIDEBAR
        }
    },
    {
        path: '/blogarticles',
        name: 'blog-articles',
        component: areaBlogList,
        meta: {
            requiresAuth: true,
            layout: 'DefaultLayout',
            sidebar: 'viewNewBlogArticles',
            showSidebar: CONFIG.WLCONFG.SHOW_BLOG_SIDEBAR
        }
    },
    {
        path: '/blogarticle/:article_id',
        name: 'blog-article',
        props: true,
        component: areaBlogArticle,
        meta: {
            requiresAuth: true,
            layout: 'DefaultLayout',
            sidebar: 'viewNewBlogArticles',
            showSidebar: CONFIG.WLCONFG.SHOW_BLOG_ARTICLE_SIDEBAR
        }
    },
    {
        path: '/ai-tools',
        name: 'ai-tools',
        component: areaAiTools,
        meta: {
            requiresAuth: true,
            layout: 'DefaultLayout',
            sidebar: 'viewNewBlogArticles',
            showSidebar: CONFIG.WLCONFG.SHOW_AITOOLS_SIDEBAR
        }
    },
    {
        path: '/transcriber',
        name: 'transcriber',
        component: areaTranscriber,
        meta: {
            requiresAuth: true,
            layout: 'DefaultLayout',
            sidebar: 'viewAiToken',
            showSidebar: CONFIG.WLCONFG.SHOW_TRANSCRIBER_SIDEBAR
        }
    },
    {
        path: '/myChecklistOverview/:company_checklist_id',
        name: 'my-checklist-overview',
        component: areaMyChecklistOverview,
        props: true,
        meta: {
            requiresAuth: true,
            layout: 'DefaultOneColumnLayout'
        }
    },
    {
        path: '/companyList',
        name: 'Firmen',
        component: areaCompanyList,
        meta: {
            requiresAuth: true,
            layout: 'DefaultOneColumnLayout'
        }
    },
    {
        path: '/dashboard',
        name: 'dashboard',
        components: {
            default: viewDashboard
        },
        meta: {
            requiresAuth: true,
            layout: 'DefaultLayout',
            sidebar: 'viewTasks',
            showSidebar: CONFIG.WLCONFG.SHOW_DASHBOARD_SIDEBAR
        }
    },
    {
        path: '/danke',
        name: 'danke',
        component: areaDanke,
        meta: {
            requiresAuth: false,
            layout: 'FullWidthLayout'
        }
    },
    {
        path: '/:pathMatch(.*)*',
        name: 'error-404',
        component: areaError404,
        meta: {
            requiresAuth: false,
            layout: 'FullWidthLayout'
        }
    }

]

const router = new VueRouter ({
    mode: 'history',
    routes
})

router.beforeEach((to, from, next) => {
    if (!store.getters.getIsLoggedIn && to.meta.requiresAuth) {
        next('/login' );
        //next()
    } else {
        store.dispatch('setFromRoute', from);
        store.dispatch('setToRoute', to);
        next() // go to wherever I'm going
    }

})

//this.$router

export default router;