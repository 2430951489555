<template>
  <b-container fluid="xxl" class="position-relative">

    <template>      
        <hocSticky class="wl-sticky__save-sticky">
          <div class="w-100 d-flex justify-content-end">
            <b-button
                type="button"
                variant="cta"
                class="button-cta rounded-circle icon"
                @click="saveRecords"
            ><font-awesome-icon icon="fa-solid fa-floppy-disk" /></b-button>
         </div>
        </hocSticky>
    </template>
    

    <b-row>
      <b-col cols="12">
        <h3>{{ $t('WEEKLY_DATA_COLLECTION_HEADLINE') }}</h3>
        <p class="mb-5" v-if="getRecordDataWeekly.sum > 0" v-html="$t('WEEKLY_DATA_COLLECTION_TEXT')"></p>
        <p class="mb-5" v-else>{{ $t('TASK_TEXT_COMPLETE') }}</p>
      </b-col>
    </b-row>
    <b-row>
          
    <template v-for="date in getRecordingDates"
        >
          <b-col cols="12" xl="6"  class="mt-5" :key="date.begin_formatted" v-if="findRecord(date.year, date.week, 'turnover') == 0 || findRecord(date.year, date.week, 'visitor') == 0">
            <hocCard>
              <h6>{{ date.year }}, KW {{ date.week_str}}</h6>
              <p>{{ date.begin_formatted }} - {{ date.end_formatted }}</p>

              <div class="d-flex flex-column flex-xxl-row">
                <b-form-group
                class="mr-xxl-2"
                  :label="$t('MISSING_ENTRIES_FOR_TURNOVER')"
                  :label-for="'turnover_' + date.year + '_' + date.week_str"
                  v-if="findRecord(date.year, date.week, 'turnover') == 0"
              >
                <b-input-group append-html="&euro;" class="mb-2 mr-sm-2 mb-sm-0">
                  <b-form-input
                      name="turnover"
                      :id="'turnover_' + date.year + '_' + date.week_str"
                      type="text"
                      placeholder="Umsatz"
                      :state="validation('decimal', 'turnover', date.year + '_' + date.week)"
                      v-model.trim="turnover[date.year + '_' + date.week]"
                  ></b-form-input>
                </b-input-group>
              </b-form-group>

              <b-form-group
              class="ml-xxl-2"
                  :label="$t('MISSING_ENTRIES_FOR_VISITORS')"
                  :label-for="'visitors_' + date.year + '_' + date.week_str"
                  v-if="findRecord(date.year, date.week, 'visitor') == 0"
              >
                <b-input-group append-html="&sum;" class="mb-2 mr-sm-2 mb-sm-0">
                  <b-form-input
                      name="visitors"
                      :id="'visitors_' + date.year + '_' + date.week_str"
                      type="text"
                      placeholder="Besucher"
                      :state="validation('number', 'visitors', date.year + '_' + date.week)"
                      v-model.trim="visitors[date.year + '_' + date.week]"
                  ></b-form-input>
                </b-input-group>
              </b-form-group>
              </div>
             
          </hocCard>
          </b-col>
        </template>
        </b-row>
  </b-container>
</template>

<script>
import axios from 'axios';
import { mapMutations, mapGetters, mapActions } from 'vuex';
import hocCard from '@/components/hoc/hocCard';
import hocSticky from '@/components/hoc/hocSticky.vue';

export default {
  name: "areaRecordingWeeks",
  components: {
    hocCard,
    hocSticky
  },
  data() {
    return {
      turnover: {},
      visitors: {},
      formDataError: {},
      API_URL: process.env.VUE_APP_API,
      apiError: false,
      apiErrorMessage: '',
      apiSuccess: false,
      autoHideToast: 4000
    }
  },
  computed: {
    ...mapGetters([
      'getRecordingDates',
      'getRecordDataWeekly'
    ]),
    recordingDatesCounter() {

      //let dates = this.getRecoringDates;
      //console.log(this.getRecordingDates);
      return this.getRecordingDates.length;

    },
  },
  methods: {
    ...mapMutations([]),
    ...mapActions([]),
    ...mapGetters([
        'getToken',
        'getRecords'
    ]),
    findRecord(year, week, type) {

      let records = this.$store.getters.getRecords[type];

      //console.log(records);
      //console.log(year, week, type);

      if (typeof records === 'undefined') return 0;

      for (const key in records) {
        //console.log(key);
        //console.log(records[key]);

        if (records[key].year == year && records[key].week == week) {
          if (type === 'turnover') {

            this.turnover[year + '_' + week] = records[key].earning;

            return records[key].earning;

          } else {

            this.visitors[year + '_' + week] = records[key].visitor;

            return records[key].visitor;

          }
        }
      }
      return 0;

    },
    validation(type, area, key) {

      var ret = false;
      //var data = this.formData[type];
      var data = this[area][key];

      switch(type) {

        case 'decimal':

          //var reg = /^[0-9]*[,]*[0-9]{0,1,2}$/; /* eslint-disable-line */
          var reg = /^(\d+(?:[\.\,]\d{2})?)$/; /* eslint-disable-line */

          if (reg.test(data)) {
            ret = true;
          } else {
            ret = false;
          }

          break;

        case 'number':

          var reg = /^[0-9]*$/; /* eslint-disable-line */

          if (reg.test(data)) {
            ret = true;
          } else {
            ret = false;
          }

          break;

        default:
          ret = true;
          break;


      }

      this.formDataError[type] = ret;
      this.isFormError();
      return ret;

    },
    isFormError() {

      var form = this.formDataError;

      //console.log(form);
      if (form.length === 0) return true;

      for (const key in form) {
        //console.log(key);
        if (!form[key]) {
          //console.log(false);
          return false;
        }
      }

      return true;

    },
    saveRecords() {

      //var form = this.turnover;

      //console.log(form);
      //console.log(form.length);
      //console.log(Object.keys(form).length);
      //if (Object.keys(form).length === 0) return true;

      /*
      for (const key in form) {
        console.log(key);
        console.log(form[key]);
      }

       */

      this.sendRecords();


    },
    async updateRecords() {

      //todo: local development
      var cookie = this.$store.getters.getToken;

      var startDate = this.$store.getters.getRecordingStartDate;
      var endDate = this.$store.getters.getRecordingEndDate;

      //console.log('start: ', startDate);
      //console.log('end: ', endDate);

      const response = await axios.post(
          this.API_URL,
          {
            type: 'get_records',
            startDate: startDate,
            endDate: endDate,
            cookie: cookie,
          }
      );

      //console.log(response);

      if (response.data.error) {

        //console.log(response);
        this.$store.dispatch('setRecords', {});

      } else {

        //console.log(response.data);
        this.$store.dispatch('setRecords', response.data);

      }
    },
    async sendRecords() {

      var cookie = this.$cookies.get('PHPSESSID');
      //console.log(cookie);

      if (cookie == null) {
        cookie = '';
      }

      //todo: local development
      cookie = this.$store.getters.getToken;

      //console.log(cookie);
      //console.log('turnover: ', this.turnover);
      //console.log(this.visitors);

      const response = await axios.post(
          this.API_URL,
          {
            type: 'set_records',
            turnover: this.turnover,
            visitor: this.visitors,
            cookie: cookie,
          }
      );

      //console.log(response);

      if (response.data.error) {

        //console.log(response);
        //console.log(response.data.error);
        this.apiErrorMessage = response.data.error.join("<br/>");
        this.apiError = true;
        this.apiSuccess = false;
          this.$bvToast.toast([this.$options.filters.striptags(this.apiErrorMessage) ], {
          title: [this.$t('ERROR_TITLE_DEFAULT')],
          solid: true,
          toaster: 'b-toaster-top-center',
          variant: 'danger',
          noCloseButton: true,
          autoHideDelay: this.autoHideToast
        })

      } else {
        
       
        this.apiSuccess = true;
        this.updateRecords();
        this.$bvToast.toast([this.$t('SUCCESS_TEXT_SAVE_DATA')], {
          title: [this.$t('SUCCESS_TITLE_DEFAULT')],
          solid: true,
          toaster: 'b-toaster-top-center',
          variant: 'success',
          noCloseButton: true,
          autoHideDelay: this.autoHideToast
        })
        //this.$store.dispatch('setIsLoggedIn', true);
        //this.$store.dispatch('setUser', response.data.user);

        //this.$router.push('/recording');

      }
    },
  }
}
</script>
