<template>
  <b-container fluid="xxl">


    <b-row>
      <b-col cols="12"   xl="6" xxl="4">

        <h3>{{ $t('NAV_LINK_TEXT_AI') }}</h3>
        <template  v-if="articledata.length == 0">
          <p class="mb-5" v-html="$t('NO_TOOLS_IN_LIST')"></p>
        </template>


      </b-col>
    </b-row>



    <b-row  v-if="articledata.length > 0">

      <!-- item -->
      <b-col
          v-for="(article, article_index) in articledata"
          :key="article_index"
          cols="12" xl="6" xxl="4"
          class="mt-4 mb-3"
      >
        <ProductCard
            :no-padding="true"
            :product-id="article.module_id  | tostring"
            :product-title="article.title"
            :product-image="article.image"
            :product-cta-link="article.link_to"
            :product-btn-label="$t('LETS_START')"
            :small-layout="true"
            :product-description="article.short_description"
            v-on:submit="handleToolEvent"

        />


      </b-col>

    </b-row>



  </b-container>
</template>


<script>
import {mapActions, mapGetters} from "vuex";
import ProductCard from '@/components/ProductCard.vue';
import axios from "axios";
export default {
  name: "areaAiTools",
  components: {
    ProductCard
  },
  data () {
    return {
      articledata: [],
      API_URL: process.env.VUE_APP_API
    }
  },
  mounted() {
    this.loadToolData();

  },
  computed: {
    ...mapGetters( {
    }),

  },
  methods: {
    ...mapActions([
    ]),

    async loadToolDataOld() {
      const response = await axios.get('/staticMockData/aitools.json');
      this.articledata = response.data.articles;
    },

    async loadToolData() {

      //todo: local development
      var cookie = this.$store.getters.getToken;

      const response = await axios.post(
          this.API_URL,
          {
            type: 'get_ai_modules',
            cookie: cookie
          }
      );

      //console.log(response);

      //this.$store.dispatch('setCategories', response.data);
      this.articledata = response.data;

    },

    async handleToolEvent(data) {
      if(!data || !("type" in data) ) return;
      const url = data.url;
      if (this.$route.path !== url) this.$router.push(url);
    },

  },


}
</script>

<style scoped>

</style>