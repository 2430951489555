<template>
  <div>
    <p class="justify-content-md-center mb-3" v-if="!emailTransmitted">{{ $t('PASSWORD_FORGOTTEN_DEFAULT_TEXT') }}</p>
    <div class="text-center" v-if="!emailTransmitted">
      <b-form class="wl-form__reset">
        <b-form-group
        class="mb-lg-5 mb-4"
        label=""
        label-for="email"
        >
          <b-form-input
              id="email-forgotten"
              v-model="email"
              type="email"
              :placeholder="$t('E_MAIL')"
              required
          ></b-form-input>
        </b-form-group>

        <b-button
            variant="cta"
            class="button-cta"
            @click="sendPwForgottenMail"
        >{{ $t('GET_NEW_PASSWORD')}}</b-button>
      </b-form>
    </div>
  </div>
</template>

<script>
import {mapActions, mapGetters, mapMutations} from "vuex";
import axios from "axios";

export default {
    name: "areaPasswordForgotten",
    data() {
        return {
            API_URL: process.env.VUE_APP_API,
            email: "",
            emailTransmitted: false,
            autoHideToast: 4000
        };
    },
    methods: {
        ...mapMutations([]),
        ...mapActions([
            "setCategoryActive"
        ]),
        ...mapGetters([
            "getStatisticFilterButtons",
            "getIsLoggedIn"
        ]),
        async sendPwForgottenMail() {
            await this.$root.$emit('track', {source: null, target: null, button: 'passwordForgottenForm'});
            this.emailTransmitted = true;
            await axios.post(
                this.API_URL,
                {
                  type: 'send_password_forgotten_mail',
                  email: this.email,
                }
            );
      
            this.$bvToast.toast([this.$t("PASSWORD_FORGOTTEN_RESPONSE_TEXT")], {
                title: [this.$t("SUCCESS_TITLE_DEFAULT")],
                solid: true,
                toaster: "b-toaster-top-center",
                variant: "success",
                noCloseButton: true,
                autoHideDelay: this.autoHideToast
            });
            this.email = "";
            this.emailTransmitted = false;
        },
    }
}
</script>

<style scoped>

</style>