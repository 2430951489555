<template>
  <b-container>

    <!--
    {{ product_id }}
    <b-button @click="showSaveButton(true)">showSaveButton</b-button>
    <b-button @click="showSaveButton(false)">hideSaveButton</b-button>
    <b-button @click="testData()">testdata</b-button>

    <p><br/><br/><br/><br/></p>
    -->

    <!-- main-category -->

    <div
      v-for="(main_category, main_index) in getChecklistItems"
      :key="main_index"
      >
      <b-row class="mt-5">

        <b-col
            cols="8"
            @click="showSidebar('checklistSidebar', main_index, 0, 'main_category')"
            class="cursor-pointer"
        >

          <p class="h3 cursor-pointer">
            <font-awesome-icon
                :icon="['fa-solid', main_category.category_icon]"
                class="cursor-pointer mr-1 link-primary"
            />
            {{ main_category.category_title }}
          </p>
        </b-col>

        <b-col cols="3"
           @click="showSidebar('checklistSidebar', main_index, 0, 'main_category')"
           class="cursor-pointer"
        >
          <b-progress class="mt-2" :max="max" show-value>
            <b-progress-bar :value="main_category.category_weight" :class="getProgressCss('progress-impact-', main_category.category_weight)"></b-progress-bar>
          </b-progress>
        </b-col>

        <b-col cols="1" class="">
          <p class="text-right">

            <span v-if="main_index == Object.keys(getChecklistItems).length - 1">
              <font-awesome-icon
                  :icon="['fa-solid', 'fa-plus']"
                  class="cursor-pointer ml-2 link-primary"
                  title="Neue Hauptkategorie anlegen"
                  @click="newItem('main', main_category.product_checklist_main_category_id, 0)"
              />
            </span>

            <font-awesome-icon
                :icon="['fa-solid', 'fa-trash-can']"
                class="cursor-pointer ml-2 text-danger"
                @click="deleteItem(main_category.product_checklist_main_category_id, 0, 0)"
            />

          </p>
        </b-col>

      </b-row>

      <!-- sub-category -->

      <div
          v-for="(sub_category, sub_index) in main_category.sub_categories"
          :key="sub_index"
      >
        <b-row class="sub-category-line">

          <b-col
              cols="11"
              @click="showSidebar('checklistSidebar', main_index, sub_index, 'sub_category')"
              class="mt-2 cursor-pointer"
          >

            <p class="h5 cursor-pointer">
              <font-awesome-icon
                  :icon="['fa-solid', sub_category.category_icon]"
                  class="cursor-pointer mr-1 link-primary"
              />
              {{ sub_category.category_title }}
            </p>

          </b-col>

          <b-col cols="1" class="mt-2">
            <p class="text-right">

              <span v-if="sub_index == Object.keys(main_category.sub_categories).length - 1">
                <font-awesome-icon
                    :icon="['fa-solid', 'fa-plus']"
                    class="cursor-pointer link-primary"
                    title="neue Unterkategorie anlegen"
                    @click="newItem('sub', main_category.product_checklist_main_category_id, sub_category.product_checklist_sub_category_id)"
                />
              </span>

              <font-awesome-icon
                  :icon="['fa-solid', 'fa-trash-can']"
                  class="cursor-pointer ml-2 text-danger"
                  @click="deleteItem(0, sub_category.product_checklist_sub_category_id, 0)"
              />

            </p>
          </b-col>

        </b-row>

        <!-- items -->

        <div
            v-for="(item, item_index) in sub_category.items"
            :key="item_index"
        >
          <b-row
            v-if="item_index == 0"
            class="item-header mt-2"
          >

            <b-col cols="6">

              <p class="h6">
                Aufgabe
              </p>
            </b-col>

            <b-col cols="1">

              <p class="h6">
                Medien
              </p>
            </b-col>

            <b-col cols="1">
              <p class="h6">
                Nutzen
              </p>
            </b-col>

            <b-col cols="1">
              <p class="h6">
                Aufwand
              </p>
            </b-col>

            <b-col cols="2">
              <p class="h6">
                Priorität
              </p>
            </b-col>

            <b-col cols="1" class="">

            </b-col>

          </b-row>


          <b-row class="item-line">

            <b-col
                cols="6"
                @click="openItemEditor(main_index, sub_index, item_index, item.product_checklist_item_id)"
                class="cursor-pointer"
            >

              <p class="">
                {{ item.item_description }}
              </p>

            </b-col>

            <b-col
                cols="1"
                @click="openItemEditor(main_index, sub_index, item_index, item.product_checklist_item_id)"
                class="cursor-pointer text-center"
            >

              <p class="">
                {{ countMedia(item) }}
              </p>

            </b-col>

            <b-col
                cols="1"
                @click="openItemEditor(main_index, sub_index, item_index, item.product_checklist_item_id)"
                class="cursor-pointer"
            >
              <b-progress class="mt-2" :max="max" show-value>
                <b-progress-bar :value="item.item_impact" :class="getProgressCss('progress-impact-', item.item_impact)"></b-progress-bar>
              </b-progress>
            </b-col>

            <b-col
                cols="1"
                @click="openItemEditor(main_index, sub_index, item_index, item.product_checklist_item_id)"
                class="cursor-pointer"
            >
              <b-progress class="mt-2" :max="max" show-value>
                <b-progress-bar :value="item.item_cost" :class="getProgressCss('progress-cost-', item.item_cost)"></b-progress-bar>
              </b-progress>
            </b-col>

            <b-col
                cols="2"
                @click="openItemEditor(main_index, sub_index, item_index, item.product_checklist_item_id)"
                class="cursor-pointer"
            >
              <b-progress class="mt-2" :max="max" show-value>
                <b-progress-bar :value="item.item_priority" :class="getProgressCss('progress-priority-', item.item_priority)"></b-progress-bar>
              </b-progress>
            </b-col>

            <b-col cols="1" class="">
              <p class="text-right">

                <span v-if="item_index == Object.keys(sub_category.items).length - 1">
                  <font-awesome-icon
                      :icon="['fa-solid', 'fa-plus']"
                      class="cursor-pointer link-primary"
                      title="neue Aufgabe anlegen"
                      @click="newItem('item', main_category.product_checklist_main_category_id, sub_category.product_checklist_sub_category_id)"
                  />
                </span>

                <font-awesome-icon
                    :icon="['fa-solid', 'fa-trash-can']"
                    class="cursor-pointer ml-2 text-danger"
                    @click="deleteItem(0, 0, item.product_checklist_item_id)"
                />
              </p>
            </b-col>



          </b-row>

        </div>

      </div>



    </div>

    <b-row class="mt-5">

      <b-col
          cols="12"
      >

        <b-button
            type="button"
            variant="primary"
            class="mt-4 mb-4 button-primary"
            @click="saveChecklist"
        >Checkliste speichern</b-button>

      </b-col>

    </b-row>




    <b-sidebar id="checklistSidebar" title="Einstellungen" shadow>
      <div class="px-3 py-2">

        <div
          v-if="getActualSidebarArea == 'main_category'"
          >
            <!-- main-category -->

            <h4>Hauptkategorie</h4>

            <b-form-group
                label="Icon:"
                label-for="main_category_icon"
            >
              <b-form-input
                  name="main_category_icon"
                  id="main_category_icon"
                  type="text"
                  placeholder="Icon"
                  :state="validation('main_category_icon')"
                  v-model.trim="mainCategoryIcon"
              ></b-form-input>
              <b-form-invalid-feedback :state="validation('main_category_icon')">
                Das Icon kann frei bleiben.
              </b-form-invalid-feedback>
              <b-form-valid-feedback :state="validation('main_category_icon')"></b-form-valid-feedback>
            </b-form-group>

            <b-form-group
                label="Titel:"
                label-for="main_category_title"
            >
              <b-form-input
                  name="main_category_title"
                  id="main_category_title"
                  type="text"
                  placeholder="Titel"
                  :state="validation('main_category_title')"
                  v-model.trim="mainCategoryTitle"
              ></b-form-input>
              <b-form-invalid-feedback :state="validation('main_category_title')">
                Der Titel muss mindestens 5 Zeichen lang sein.
              </b-form-invalid-feedback>
              <b-form-valid-feedback :state="validation('main_category_title')"></b-form-valid-feedback>
            </b-form-group>

            <b-form-group
                label="Wichtigkeit:"
                label-for="main_category_weight"
            >
              <b-input-group :append="mainCategoryWeight">
                <b-form-input
                    name="main_category_weight"
                    id="main_category_weight"
                    type="range"
                    min="0"
                    max="10"
                    v-model.trim="mainCategoryWeight"
                ></b-form-input>
              </b-input-group>
            </b-form-group>

            <b-form-group
                label="Beschreibung:"
                label-for="main_category_description"
            >
              <b-form-textarea
                  id="main_category_description"
                  v-model.trim="mainCategoryDescription"
                  placeholder="Kategoriebeschreibung..."
                  rows="4"
              ></b-form-textarea>
            </b-form-group>

            <b-form-group
                label="Sortierung:"
                label-for="main_category_sort"
            >
              <b-input-group :append="mainCategorySort">
                <b-form-input
                    name="main_category_sort"
                    id="main_category_sort"
                    type="range"
                    min="0"
                    max="100"
                    v-model.trim="mainCategorySort"
                ></b-form-input>
              </b-input-group>
            </b-form-group>

        </div>

        <div
            v-if="getActualSidebarArea == 'sub_category'"
        >
          <!-- sub-category -->

          <h4>Unterkategorie</h4>

          <b-form-group
              label="Icon:"
              label-for="sub_category_icon"
          >
            <b-form-input
                name="sub_category_icon"
                id="sub_category_icon"
                type="text"
                placeholder="Icon"
                :state="validation('sub_category_icon')"
                v-model.trim="subCategoryIcon"
            ></b-form-input>
            <b-form-invalid-feedback :state="validation('sub_category_icon')">
              Das Icon kann frei bleiben.
            </b-form-invalid-feedback>
            <b-form-valid-feedback :state="validation('sub_category_icon')"></b-form-valid-feedback>
          </b-form-group>

          <b-form-group
              label="Titel:"
              label-for="sub_category_title"
          >
            <b-form-input
                name="sub_category_title"
                id="sub_category_title"
                type="text"
                placeholder="Titel"
                :state="validation('sub_category_title')"
                v-model.trim="subCategoryTitle"
            ></b-form-input>
            <b-form-invalid-feedback :state="validation('sub_category_title')">
              Der Titel muss mindestens 5 Zeichen lang sein.
            </b-form-invalid-feedback>
            <b-form-valid-feedback :state="validation('sub_category_title')"></b-form-valid-feedback>
          </b-form-group>

          <b-form-group
              label="Beschreibung:"
              label-for="sub_category_description"
          >
            <b-form-textarea
                id="sub_category_description"
                v-model.trim="subCategoryDescription"
                placeholder="Kategoriebeschreibung..."
                rows="4"
            ></b-form-textarea>
          </b-form-group>

          <b-form-group
              label="Sortierung:"
              label-for="main_category_sort"
          >
            <b-input-group :append="subCategorySort">
              <b-form-input
                  name="sub_category_sort"
                  id="sub_category_sort"
                  type="range"
                  min="0"
                  max="100"
                  v-model.trim="subCategorySort"
              ></b-form-input>
            </b-input-group>
          </b-form-group>

        </div>

      </div>
    </b-sidebar>





    <b-modal
        id="modal-item"
        size="xl"
        title="Aufgaben-Editor">

      <!-- item -->

      <b-form-group
          label="Beschreibung:"
          label-for="item_description"
      >
        <b-form-textarea
            id="item_description"
            v-model.trim="itemDescription"
            :state="validation('item_description')"
            placeholder="Aufgabenbeschreibung..."
            rows="4"
        ></b-form-textarea>
        <b-form-invalid-feedback :state="validation('item_description')">
          Der Titel muss mindestens 5 Zeichen lang sein.
        </b-form-invalid-feedback>
        <b-form-valid-feedback :state="validation('item_description')"></b-form-valid-feedback>
      </b-form-group>

      <b-row v-if="!showItemSwitchDialog">

        <b-col cols="12" class="text-right">
          <font-awesome-icon
              :icon="['fa-solid', 'fa-share-from-square']"
              class="cursor-pointer"
              @click="handleItemSwitchDialog(true)"
          />

        </b-col>

      </b-row>

      <b-row v-if="showItemSwitchDialog">

        <b-col cols="12" class="text-right">
          <font-awesome-icon
              :icon="['fa-solid', 'fa-xmark']"
              class="cursor-pointer"
              @click="handleItemSwitchDialog(false)"
          />
        </b-col>

      </b-row>

      <b-row v-if="showItemSwitchDialog">

        <b-col cols="12" class="">
          <h5>Aufgabe verschieben</h5><br/>

          <h6>Checkliste auswählen</h6>

          <ul>
            <li
                v-for="(switch_checklist, switch_index) in itemSwitchChecklists"
                :key="switch_index"
                @click="itemSwitchLoadChecklist(switch_checklist)" :class="'list-hover cursor-pointer ' + classItemSwitchDialog(switch_checklist.product_checklist_id)">{{ switch_checklist.product_name }}</li>
          </ul>

          <div v-if="showItemSwitchCategories">

            <p>Ausgewählte Checkliste: <strong>{{ itemSwitchChecklist.product_name }}</strong></p>
            <br/>
            <h6>Wähle die Unterkategorie:</h6>
            <br/>

            <div
              v-for="(switch_main, switch_main_index) in itemSwitchChecklist.main_categories"
              :key="switch_main_index">
              <p><strong>{{ switch_main.category_title }}</strong></p>

              <ul>
                <li
                    v-for="(switch_sub, switch_sub_index) in switch_main.sub_categories"
                    :key="switch_sub_index"
                    @click="itemSwitchItemToSubCategory(switch_sub.product_checklist_sub_category_id, getChecklistItem.product_checklist_item_id)" class="list-hover cursor-pointer">{{ switch_sub.category_title }}</li>
              </ul>

            </div>

            <b-alert
                :show="showItemSwitchError"
                variant="danger"
            >
              <p>Es gab einen Fehler. Eine Aufgabe kann nur dann verschoben werden, wenn die Unterkategorie noch mindestens eine Aufgabe hat. Bitte lege vorher eine weitere Aufgabe an, um danach diese Aufgabe hier verschieben zu können.</p>
            </b-alert>

          </div>
        </b-col>

      </b-row>

      <b-row>

        <b-col cols="6">

          <b-form-group
              label="Nutzen:"
              label-for="item_impact"
          >
            <b-form-input
                name="item_impact"
                id="item_impact"
                type="range"
                min="0"
                max="10"
                v-model.trim="itemImpact"
            ></b-form-input>
          </b-form-group>
        </b-col>

        <b-col cols="3" class="item-range-value">
          {{ itemImpact }}
        </b-col>

      </b-row>

      <b-row>

        <b-col cols="6">

          <b-form-group
              label="Aufwand:"
              label-for="item_cost"
          >
            <b-form-input
                name="item_cost"
                id="item_cost"
                type="range"
                min="0"
                max="10"
                v-model.trim="itemCost"
            ></b-form-input>
          </b-form-group>

        </b-col>

        <b-col cols="3" class="item-range-value">
          {{ itemCost }}
        </b-col>

      </b-row>

      <b-row>

        <b-col cols="6">

          <b-form-group
              label="Priorität:"
              label-for="item_priority"
          >
            <b-form-input
                name="item_priority"
                id="item_priority"
                type="range"
                min="0"
                max="10"
                v-model.trim="itemPriority"
            ></b-form-input>
          </b-form-group>

        </b-col>

        <b-col cols="3" class="item-range-value">
          {{ itemPriority }}
        </b-col>

      </b-row>

      <b-row>

        <b-col cols="12">

          <b-form-group
              label="URL / Video:"
              label-for="uvdata"
          >
            <b-input-group>
              <b-form-input
                  name="uvdata"
                  id="uvdata"
                  type="text"
                  placeholder="URL / Video"
                  :state="validation('url')"
                  v-model.trim="uvdata"
              ></b-form-input>
              <b-input-group-append>
                <b-button
                    type="button"
                    variant="primary"
                    @click="sendUrlVideo()"
                ><font-awesome-icon
                    :icon="['fa-solid', 'fa-plus']"
                    class="cursor-pointer"
                /></b-button>
              </b-input-group-append>
            </b-input-group>
            <b-form-invalid-feedback :state="validation('url')">
              Die URL muss mit https beginnen.
            </b-form-invalid-feedback>
            <b-form-valid-feedback :state="validation('url')"></b-form-valid-feedback>
          </b-form-group>

        </b-col>
      </b-row>



      <vue-dropzone
          ref="exampleDropzone"
          id="dropzoneItem"
          :options="dropzoneOptions"
          v-on:vdropzone-sending="uploadEvent"
          v-on:vdropzone-success="fileUploaded"
      ></vue-dropzone>


      <div
          v-for="(media, media_index) in getMedia"
          :key="media_index"
          >

        <b-row
            v-if="media_index == 0"
            class="media-line mt-5">

          <b-col cols="3">

            <h5>Bild / Icon</h5>

          </b-col>

          <b-col cols="6">

            <h5>Identifier</h5>

          </b-col>

          <b-col cols="3">
            <h5>Medien-Typ</h5>
          </b-col>

        </b-row>

        <b-row class="media-line mt-2">

          <b-col cols="3" class="pt-10 pb-10 text-center">

            <span
                v-if="createTag(media) !== ''"
                v-html="createTag(media)"></span>

            <span
                v-if="media.media_type_description === 'URL'"
            >
              <font-awesome-icon
                  :icon="['fa-solid', 'fa-link']"
                  class="link-primary media-icon"
              />
            </span>

            <span
                v-if="media.media_type_description === 'Dokument'"
            >
              <font-awesome-icon
                  :icon="['fa-solid', 'fa-file']"
                  class="link-primary media-icon"
              />
            </span>

          </b-col>

          <b-col cols="6" class="pt-10 pb-10">

            <b-button
                type="button"
                variant="link"
                class="link-primary"
                @click="copyText(media.identifier)">{{ media.identifier }}</b-button>

          </b-col>

          <b-col cols="3" class="pt-10 pb-10">
            {{ media.media_type_description }}
          </b-col>

        </b-row>

      </div>


      <b-form-group
          label="Beispiele:"
          label-for="item_example"
      >
        <b-form-textarea
            id="item_example"
            v-model.trim="itemExample"
            placeholder="Beispiele..."
            rows="4"
        ></b-form-textarea>
      </b-form-group>

      <b-button
        v-if="itemExample != ''"
        variant="primary"
        class="mr-3 button-primary" @click="preview()" type="button">Vorschau</b-button>


    </b-modal>




    <b-overlay :show="busy" no-wrap @shown="onShown" @hidden="onHidden">
      <template #overlay>
        <div v-if="processing" class="text-center p-4 bg-primary text-light rounded">
          <b-icon icon="cloud-upload" font-scale="4"></b-icon>
          <div class="mb-3">Processing...</div>
          <b-progress
              min="1"
              max="20"
              :value="counter"
              variant="success"
              height="3px"
              class="mx-n4 rounded-0"
          ></b-progress>
        </div>
        <div
            v-else
            ref="dialog"
            tabindex="-1"
            role="dialog"
            aria-modal="false"
            aria-labelledby="form-confirm-label"
            class="text-center p-3"
        >
          <p><strong id="form-confirm-label">Bist Du Dir sicher?</strong></p>
          <div class="d-flex">
            <b-button variant="danger" class="mr-3" @click="onCancel" type="button">
              Cancel
            </b-button>
            <b-button variant="success" @click="onOK" type="button">OK</b-button>
          </div>
        </div>
      </template>
    </b-overlay>

    <b-modal size="xl" ref="preview-modal" hide-footer :title="previewModalTitle">
      <div class="d-block text-center">
        <p v-html="previewModalContent"></p>
      </div>
    </b-modal>


  </b-container>
</template>

<script>
import {mapActions, mapGetters, mapMutations} from "vuex";
import vue2Dropzone from 'vue2-dropzone';
import 'vue2-dropzone/dist/vue2Dropzone.min.css';
import axios from "axios";
/* https://rowanwins.github.io/vue-dropzone/docs/dist/#/events */
const CONFIG = require(`@/wl-config/${process.env.VUE_APP_WL_FOLDER}/wl-app-config`)

export default {
  name: "productEditChecklist",
  props: ['product_id'],
  components: {
    vueDropzone: vue2Dropzone,
  },
  data() {
    return {
      formData: {
        title: '',
        url: '',
        price: '',
        pseudoprice: '',
        description: '',
        product_type: '',
      },
      formDataError: {},

      value: 5,
      valueImpact: 3,
      valueCost: 5,
      valuePriority: 8,


      API_URL_UPLOAD: process.env.VUE_APP_UPLOAD,
      API_URL: process.env.VUE_APP_API,
      headerInfo: CONFIG.WLCONFG.META_SAFE_EQUIV,
      max: 10,
      uvdata: '',

      dropzoneOptions: {
        url: process.env.VUE_APP_UPLOAD,
        thumbnailWidth: 150,
        maxFilesize: 1000,
        addRemoveLinks: true,
        dictDefaultMessage: "<i class='fa fa-cloud-upload'></i>Lade hier Dateien für die Beispiele hoch.",
        headers: { headerInfo: "checklistItemUpload" }
      },

      busy: false,
      processing: false,
      counter: 1,
      interval: null,
      delete_main_id: 0,
      delete_sub_id: 0,
      delete_item_id: 0,

      previewModalTitle: '',
      previewModalContent: '',

      showItemSwitchDialog: false,
      showItemSwitchCategories: false,
      itemSwitchChecklists: {},
      itemSwitchChecklistId: 0,
      itemSwitchChecklist: {},
      showItemSwitchError: false,

      product_checklist_id: 0,

    }
  },
  created() {
    this.getChecklistData();

    this.$store.dispatch('setActualMainCategoryIndex', 0);
    this.$store.dispatch('setActualSubCategoryIndex', 0);
    this.$store.dispatch('setActualItemIndex', 0);
    this.$store.dispatch('setActualItemId', 0);

  },
  computed: {
    ...mapGetters([
      'getProductList',
      'getChecklistItems',
      'getActualMainCategoryIndex',
      'getActualSubCategoryIndex',
      'getActualItemIndex',
      'getActualSidebarArea',
      'getActualItemId',
      'getChecklistItemMain',
      'getChecklistItemSub',
      'getChecklistItem',
    ]),
    ...mapActions([
      'setChecklistItems',
      'setMainCategory',
      'setSubCategory',
      'setChecklistItem',
      'setMediaTestData',
      'setActualMainCategoryIndex',
      'setActualSubCategoryIndex',
      'setActualItemIndex',
      'setActualSidebarArea',
      'setActualItemId',
    ]),
    mainCategoryTitle: {
      get () {
        return this.getChecklistItemMain.category_title;
      },
      set (value) {
        this.$store.dispatch('setMainCategory', {category_title: value});
      }
    },
    mainCategoryIcon: {
      get () {
        return this.getChecklistItemMain.category_icon;
      },
      set (value) {
        this.$store.dispatch('setMainCategory', {category_icon: value});
      }
    },
    mainCategoryWeight: {
      get () {
        return this.getChecklistItemMain.category_weight;
      },
      set (value) {
        this.$store.dispatch('setMainCategory', {category_weight: value});
      }
    },
    mainCategorySort: {
      get () {
        return this.getChecklistItemMain.category_sort;
      },
      set (value) {
        this.$store.dispatch('setMainCategory', {category_sort: value});
      }
    },
    mainCategoryDescription: {
      get () {
        return this.getChecklistItemMain.category_description;
      },
      set (value) {
        this.$store.dispatch('setMainCategory', {category_description: value});
      }
    },
    subCategoryTitle: {
      get () {
        return this.getChecklistItemSub.category_title;
      },
      set (value) {
        this.$store.dispatch('setSubCategory', {category_title: value});
      }
    },
    subCategoryIcon: {
      get () {
        return this.getChecklistItemSub.category_icon;
      },
      set (value) {
        this.$store.dispatch('setSubCategory', {category_icon: value});
      }
    },
    subCategorySort: {
      get () {
        return this.getChecklistItemSub.category_sort;
      },
      set (value) {
        this.$store.dispatch('setSubCategory', {category_sort: value});
      }
    },
    subCategoryDescription: {
      get () {
        return this.getChecklistItemSub.category_description;
      },
      set (value) {
        this.$store.dispatch('setSubCategory', {category_description: value});
      }
    },
    itemDescription: {
      get () {
        return this.getChecklistItem.item_description;
      },
      set (value) {
        this.$store.dispatch('setChecklistItem', {item_description: value});
      }
    },
    itemImpact: {
      get () {
        return this.getChecklistItem.item_impact;
      },
      set (value) {
        this.$store.dispatch('setChecklistItem', {item_impact: value});
      }
    },
    itemCost: {
      get () {
        return this.getChecklistItem.item_cost;
      },
      set (value) {
        this.$store.dispatch('setChecklistItem', {item_cost: value});
      }
    },
    itemPriority: {
      get () {
        return this.getChecklistItem.item_priority;
      },
      set (value) {
        this.$store.dispatch('setChecklistItem', {item_priority: value});
      }
    },
    itemExample: {
      get () {
        return this.getChecklistItem.item_example;
      },
      set (value) {
        this.$store.dispatch('setChecklistItem', {item_example: value});
      }
    },
    getMedia() {
      return this.getChecklistItem.media;
    }
  },
  methods: {
    ...mapMutations([
    ]),
    ...mapActions([
      'setChecklistItemMedia',
    ]),
    ...mapGetters([
    ]),
    clearInterval() {
      if (this.interval) {
        clearInterval(this.interval)
        this.interval = null
      }
    },
    onShown() {
      // Focus the dialog prompt
      this.$refs.dialog.focus()
    },
    onHidden() {
      // In this case, we return focus to the submit button
      // You may need to alter this based on your application requirements
      //this.$refs.submit.focus()
    },
    onSubmit() {
      this.processing = false
      this.busy = true
    },
    deleteItem(main_id, sub_id, item_id) {
      this.processing = false;
      this.busy = true;
      this.delete_main_id = main_id;
      this.delete_sub_id = sub_id;
      this.delete_item_id = item_id;
    },
    onCancel() {
      this.busy = false
    },
    async onOK() {
      this.counter = 1;
      this.processing = true;

      await this.deleteTree();

      // Simulate an async request
      this.clearInterval()
      this.interval = setInterval(() => {
        if (this.counter < 20) {
          this.counter = this.counter + 1;
        } else {
          this.clearInterval()
          this.$nextTick(() => {
            this.busy = this.processing = false;
          })
        }
      }, 5);
    },
    saveProduct() {
      let product = this.product;
      //let product_id = Number(this.product_id);

      let myForm = document.getElementById('productEditForm');
      let formData = new FormData(myForm);

      //console.log(formData);

      const data = {};

      for (let [key, val] of formData.entries()) {
        //console.log(data);
        //console.log(key);
        Object.assign(data, {[key]: val})

      }

      //console.log('data');
      //console.log(data);

      product.product_name = data.title;
      product.product_url = data.url;
      product.product_description = data.description;
      product.product_price = data.price;
      product.product_pseudoprice = data.pseudoprice;
      product.product_type_id = data.product_type;

      //console.log(product);

      this.productEdit(product);

    },
    showSaveButton(status) {
      this.$emit('updateShowSaveChildComponent2', status);
    },
    showSidebar(id, main_index, sub_index, area) {
      //console.log(area);

      this.$store.dispatch('setActualMainCategoryIndex', main_index);
      this.$store.dispatch('setActualSubCategoryIndex', sub_index);
      this.$store.dispatch('setActualSidebarArea', area);

      this.$root.$emit('bv::toggle::collapse', id);
    },
    openItemEditor(main_index, sub_index, item_index, item_id) {

      this.$store.dispatch('setActualMainCategoryIndex', main_index);
      this.$store.dispatch('setActualSubCategoryIndex', sub_index);
      this.$store.dispatch('setActualItemIndex', item_index);
      this.$store.dispatch('setActualItemId', item_id);

      this.showItemSwitchDialog = false;

      this.$bvModal.show('modal-item');
    },
    uploadEvent (file, xhr, formData) {

      if (this.getActualItemId > 0) {
        formData.append('product_checklist_item_id', this.getActualItemId);
      }

    },
    fileUploaded(file, response) {
      console.log(file);
      //console.log(response);

      let result = JSON.parse(response);

      //console.log(result.product_media_id);

      if (result.product_media_id === undefined) return;

      this.$store.dispatch('setChecklistItemMedia', {item: result});


    },
    validation(type) {

      if (this.formData[type] === undefined) return true;

      var ret = false;
      var data = this.formData[type];



      switch(type) {

        case 'title':

          ret = true;
          if (data.length < 2) {
            ret = false;
          }

          break;

        case 'weight':
        case 'sort':

          //console.log('weight/sort', data);

          var reg = /^[0-9]+$/; /* eslint-disable-line */

          if (reg.test(data)) {
            ret = true;
          } else {
            ret = false;
          }

          break;

        default:
          ret = true;
          break;


      }

      this.formDataError[type] = ret;
      this.isFormError();
      return ret;

    },
    isFormError() {

      var form = this.formDataError;

      //console.log(form);
      if (form.length === 0) return true;

      for (const key in form) {
        //console.log(key);
        if (!form[key]) {
          //console.log(false);
          return false;
        }
      }

      return true;

    },
    getProgressCss(css, value) {

      if (value <= 3) {
        return css + 3;
      }

      if (value <= 6) {
        return css + 6;
      }

      return css + 10;

    },
    createTag(media) {

      if(media.media_type_description === undefined) return '';

      var str = '';

      switch(media.media_type_description) {

        case 'Bild':
          str += '<img src="' + media.url + '" width="100" border="0"/>';
          break;

        case 'Dokument':
          str += '';
          break;

        case 'Vimeo':
          //str = '<iframe src="http://player.vimeo.com/video/' + media.filename_no_extension + '" width="100%" frameborder="0" allow="autoplay; fullscreen" allowfullscreen></iframe>';

          str = '<div style="padding:51.3% 0 0 0;position:relative;"><iframe src="https://player.vimeo.com/video/' + media.filename_no_extension + '?h=1b6c086b95&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen style="position:absolute;top:0;left:0;width:100%;height:100%;" title="Back to Top Link"></iframe></div>';

          break;

        case 'Youtube':
          str = '<iframe width="100%" src="https://www.youtube-nocookie.com/embed/' + media.filename_no_extension + '" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>';
          break;

        case 'URL':
          str += "";
          break;

      }

      //console.log(str);

      return str;

    },
    copyText(content) {
      navigator.clipboard.writeText(content);
    },
    countMedia(item) {

      if (item.media === undefined) return 0;

      return Object.keys(item.media).length;

    },
    async sendUrlVideo() {

      if (this.uvdata === '') return;

      //todo: url validierung, nur wenn ok
      //todo: local development
      var cookie = this.$store.getters.getToken;

      var uvdata = this.uvdata;
      this.uvdata = '';

      const response = await axios.post(
          this.API_URL,
          {
            type: 'new_product_checklist_url_video',
            product_checklist_item_id: this.getActualItemId,
            uvdata: uvdata,
            cookie: cookie
          }
      );

      //console.log(response);

      if (response.data.error) {

        //console.log(response);

      } else {

        this.$store.dispatch('setChecklistItemMedia', {item: response.data});

      }

    },
    async getChecklistData() {

      this.$store.dispatch('setChecklistItems', {});

      //todo: local development
      var cookie = this.$store.getters.getToken;

      const response = await axios.post(
          this.API_URL,
          {
            type: 'get_product_checklist_data',
            product_id: this.product_id,
            cookie: cookie
          }
      );

      //console.log(response.data);

      if (response.data.main_categories === undefined) return;

      this.$store.dispatch('setChecklistItems', response.data.main_categories);

      this.product_checklist_id = response.data.product_checklist_id;

    },
    async saveChecklist() {

      let checklist = this.getChecklistItems;

      //todo: local development
      var cookie = this.$store.getters.getToken;

      const response = await axios.post(
          this.API_URL,
          {
            type: 'save_product_checklist_data',
            product_id: this.product_id,
            checklist: checklist,
            cookie: cookie
          }
      );

      console.log(response.data);

      await this.getChecklistData();

    },
    async newItem(itemType, main_id, sub_id) {

      await this.saveChecklist();

      let checklist = this.getChecklistItems;
      var product_checklist_id = checklist[0].product_checklist_id;
      var product_checklist_main_category_id = 0;
      var product_checklist_sub_category_id = 0;

      switch(itemType) {

        case 'main':
          product_checklist_main_category_id = 0;
          product_checklist_sub_category_id = 0;
          break;

        case 'sub':
          product_checklist_main_category_id = main_id;
          product_checklist_sub_category_id = 0;
          break;

        case 'item':
          product_checklist_main_category_id = main_id;
          product_checklist_sub_category_id = sub_id;
          break;

      }

      //todo: local development
      var cookie = this.$store.getters.getToken;

      const response = await axios.post(
          this.API_URL,
          {
            type: 'create_new_checklist_item',
            product_id: this.product_id,
            product_checklist_id: product_checklist_id,
            product_checklist_main_category_id: product_checklist_main_category_id,
            product_checklist_sub_category_id: product_checklist_sub_category_id,
            cookie: cookie
          }
      );

      console.log(response.data);

      await this.getChecklistData();

    },
    async deleteTree() {

      await this.saveChecklist();

      //todo: local development
      var cookie = this.$store.getters.getToken;

      const response = await axios.post(
          this.API_URL,
          {
            type: 'delete_checklist_item',
            product_id: this.product_id,
            product_checklist_main_category_id: this.delete_main_id,
            product_checklist_sub_category_id: this.delete_sub_id,
            product_checklist_item_id: this.delete_item_id,
            cookie: cookie
          }
      );

      console.log(response.data);

      await this.getChecklistData();

    },
    async preview() {
      console.log('preview');

      await this.saveChecklist();

      let item = this.getChecklistItem;

      //console.log(item);

      //todo: local development
      var cookie = this.$store.getters.getToken;

      const response = await axios.post(
          this.API_URL,
          {
            type: 'get_preview',
            product_id: this.product_id,
            product_checklist_item_id: item.product_checklist_item_id,
            cookie: cookie
          }
      );

      console.log(response.data);

      this.previewModalTitle = item.item_description;
      this.previewModalContent = response.data;

      this.$refs['preview-modal'].show();
      //this.$refs['preview-modal'].hide()
    },

    itemSwitchLoadChecklist(checklist) {
      //console.log(checklist);

      this.itemSwitchChecklistId = checklist.product_checklist_id;
      this.itemSwitchChecklist = checklist;
      this.showItemSwitchCategories = true;
    },

    async itemSwitchLoadAllChecklist() {

      //todo: local development
      var cookie = this.$store.getters.getToken;

      const response = await axios.post(
          this.API_URL,
          {
            type: 'get_checklists',
            cookie: cookie
          }
      );

      //console.log(response.data);

      this.itemSwitchChecklists = response.data;

      if (!this.itemSwitchChecklistId) {
        this.itemSwitchChecklistId = this.product_checklist_id ;
      }

    },

    handleItemSwitchDialog(show) {

      if (show) this.itemSwitchLoadAllChecklist();

      this.showItemSwitchDialog = show;

    },

    classItemSwitchDialog(id) {
      //console.log(id, this.product_checklist_id, this.itemSwitchChecklistId);
      var eclass = '';

      if (id == this.product_checklist_id) {
        if (id == this.itemSwitchChecklistId) {
          eclass = 'font-weight-bold link-primary';
        } else {
          eclass = 'font-weight-bold';
        }
      } else if (id == this.itemSwitchChecklistId) {
        eclass = 'link-primary';
      }

      return eclass;
    },

    async itemSwitchItemToSubCategory(product_checklist_sub_category_id, product_checklist_item_id) {
      //console.log(product_checklist_sub_category_id, product_checklist_item_id);

      await this.saveChecklist();

      //todo: local development
      var cookie = this.$store.getters.getToken;

      const response = await axios.post(
          this.API_URL,
          {
            type: 'switch_checklist_item',
            product_checklist_item_id: product_checklist_item_id,
            product_checklist_sub_category_id: product_checklist_sub_category_id,
            cookie: cookie
          }
      );

      if (response.data) {

        this.$bvModal.hide('modal-item');
        await this.getChecklistData();

      } else {

        this.showItemSwitchError = true;

      }



    },







    testData() {


      this.$store.dispatch('setMediaTestData', '');

    },
  }
}
</script>

<style scoped>

.progress-impact-3 {
  background-color: #9F8CCC !important;
}

.progress-impact-6 {
  background-color: #7B61B8 !important;
}

.progress-impact-10 {
  background-color: #5d4498 !important;
}

.progress-cost-3 {
  background-color: #C47EC5 !important;
}

.progress-cost-6 {
  background-color: #B053B2 !important;
}

.progress-cost-10 {
  background-color: #954497 !important;
}

.progress-priority-3 {
  background-color: #98C57E !important;
}

.progress-priority-6 {
  background-color: #81B861 !important;
}

.progress-priority-10 {
  background-color: #639744 !important;
}

.sub-category-line {
  background-color: #e9ecef;
}

.item-line {
  border-bottom: 1px solid #e9ecef;
  margin-bottom: 2px;
  margin-top: 2px;
}

.item-header {
  border-bottom: 1px solid #5d4498;
  margin-bottom: 10px;
}

.media-line {
  border-bottom: 1px solid #5d4498;
  margin-bottom: 10px !important;
}

.item-range-value {
  font-size: 26px;
  padding-top: 19px;
  font-weight: bold;
  color: #5d4498;
}

.pt-10 {
  padding-top: 10px;
}

.pb-10 {
  padding-bottom: 10px;
}

.media-icon {
  font-size: 30pt;
}

.list-hover:hover {
  text-decoration: underline;
}


</style>