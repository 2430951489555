<template>
    <hocCard :title="$t('PW_ACTIVATION_TITLE')" :subtitle="$t('PAGE_SUBTITLE')" variant="primary">
        <template #default><areaPasswordForgottenActivation :userkey="user_key"/></template>
      {{user_key}}
    </hocCard>
</template>

<script>

import areaPasswordForgottenActivation from '@/components/areaPasswordForgottenActivation';
import hocCard from '@/components/hoc/hocCard';

export default {
    name: "viewPWActivation",
    props: ['user_key'],
    components: {
        hocCard,
        areaPasswordForgottenActivation
    },
    mounted() {

      console.log('hallo', this.user_key);

    }
}
</script>

<style scoped>
</style>