<template>
    <div class="wl-progressbar" :class="`bg-${variantBg}`">

        <div class="wl-progressbar__progress" :style="barStyle" :class="`bg-${variant}`">
           <div v-if="progress > 0 && showCurrentValue" class="wl-progressbar__counter">{{Math.round(currentvalue)}}</div>
        </div>
    </div>
</template>

<script>

export default {
    name: "uiProgressbar",
     data() {
      return {
        progress:  this.getPercentage(),
        total: this.totalvalue,
      }
   },
   computed: {
      barStyle() {

        return {
          width: `${Math.round(this.progress)}%`
        }
      }
    },
    
   props: {
        showCurrentValue:{
            type: Boolean,
            default: true
        },
        variantBg: {
            type: String,
            default: 'antiflash'
        },
        variant: {
            type: String,
            default: 'success'
        },
        reverseMath:{
            type: Boolean,
            default: false
        },
        currentvalue: {
            type: Number,
            default: 0
        },
        totalvalue: {
            type: Number,
            default: 0
        }
  },

    methods: {
        getPercentage() {
          if(this.reverseMath) {
              return 100 / this.totalvalue * (this.totalvalue - this.currentvalue);
          } else {
            return 100 / this.totalvalue * this.currentvalue;
          }
           
        }
    },
  watch: {
    currentvalue(val, oldVal) {
        
        if(val != oldVal) this.progress = this.getPercentage()
            
    }
  }


}
</script>