<template>

  <b-container  fluid="xl" class="h-100 d-flex justify-content-center align-items-center mw-991">

    <b-row class="justify-content-md-center mt-4 mt-lg-7 mt-xl-10">
      <b-col cols="12" :lg="wlAppConstants.USE_CONTENT_IMAGE_ERROR_PAGE ? 9 : 12" :md="wlAppConstants.USE_CONTENT_IMAGE_ERROR_PAGE ? 8 : 12">
        <div v-if="wlAppConstants.USE_CONTENT_IMAGE_THANKS_PAGE">
          <h5>{{ $t('THANKS_TITLE')}}</h5>
          <b-img :src="getLogo()"  fluid />
        </div>
        <div v-else>
          <h2 class="mt-5 mb-4">{{ $t('THANKS_TITLE') }}</h2>
        </div>
        <p class="mt-5 mb-4">{{ $t('THANKS_TEXT') }}</p>
        <p> <b-button href="#" variant="cta" @click="$router.push('/')">{{ $t('BACK_TO_DASHBOARD') }}</b-button></p>

        <!-- funktioniert nicht mit localhost (nicht freigegeben in der main.js) -->
        <iframe :src="fbPixelFile" title="FB-Pixel" height="1" width="1" style="border:none;"></iframe>
        <!--<span v-html="fbPixel"></span>-->


      </b-col>
    </b-row>
  </b-container>

</template>


<script>
//import * as CONFIG from '@/wl-config/wl-app-config';
const CONFIG =  require(`@/wl-config/${process.env.VUE_APP_WL_FOLDER}/wl-app-config`)
import axios from "axios";


const DEFAULTCONFIG = {
  direction : 'x',
  scaleMode : 'contain',
  interval         : 35,
  fitFirstImage    : true
}

export default {
  name: "areaDanke",
  components: {

  },
  data() {
    return {
      config: DEFAULTCONFIG,
      wlAppConstants: CONFIG.WLCONFG,
      fbPixelFile: CONFIG.WLCONFG.FACEBOOK_PIXEL_FILE,
      fbPixel: '',
    }
  },
  mounted() {
    //this.loadFbData();

  },
  methods: {

    getLogo() {
      return require(`@/wl-config/${process.env.VUE_APP_WL_FOLDER}/assets/images/${this.wlAppConstants.ERROR_LOGO}`);
    },
    async loadFbData() {
      const response = await axios.get(CONFIG.WLCONFG.FACEBOOK_PIXEL_FILE);
      console.log(response);
      this.fbPixel = response.data;
    },
  }
}
</script>
