

const getFormattedDate = (date) => {

    var res = date.toISOString().slice(0,10).split(/-/);
    return res[2] + '.' + res[1] + '.' + res[0];

}

const isMobileDevice = () => {
  const ua = 'navigator' in window && 'userAgent' in navigator &&
  navigator.userAgent.toLowerCase() || '';
  return !!ua.match(/(iPhone|iPod|android|ios|iPad|windows phone|tablet)/i);
}


const checkMediaQuery = ( breakpoint ) =>window.matchMedia(`(max-width: ${breakpoint})`);


const getBreakPoint = (id) => {
  const rs = getComputedStyle(document.querySelector(':root'));
  return rs.getPropertyValue(`--breakpoint-${id}`);
}

const generateRandomHash = (hashlength) => {
    const characters = "0123456789ABCDEFGHIJKLMNPQRSTUVWXYZ";
    
    let string = "";
    for (let i = 0; i <= hashlength; i++) {
      string += characters[Math.floor(Math.random() * characters.length)];
    }
    return string;
}

const getPercent = (total, value) => {
  return Math.round(parseFloat(value) / parseFloat(total) * 100);
}

const convertDate = (date) => {
  //now our Sunday check
  let currentDate = date
  if (currentDate.getDay() > 0) {
   
    currentDate.setDate(currentDate.getDate() - ((currentDate.getDay() + 6) % 7));

    var new_start_year = currentDate.getFullYear();
    var new_start_month = currentDate.getMonth() + 1;
    var new_start_day = currentDate.getDay();

    if (new_start_month <= 9) new_start_month = '0' + new_start_month;
    if (new_start_day <= 9) new_start_day = '0' + new_start_day;

    return new_start_year + '-' + new_start_month + "-" + new_start_day;
  }

  return '';
}

const getMaxValue = (array) =>  Math.max(...array);

const getMediaImg = (type, media, prop) => {
  let url = '';
  Object.keys(media).forEach((key) => {
   
    if (type == media[key][prop]) {
      url = media[key].url;
    }

  });

  return url;
}



const getDates = (startDate, endDate) => {

    const monthnames = {
      1: 'Januar',
      2: 'Februar',
      3: 'März',
      4: 'April',
      5: 'Mai',
      6: 'Juni',
      7: 'Juli',
      8: 'August',
      9: 'September',
      10: 'Oktober',
      11: 'November',
      12: 'Dezember',
    };
  

  //first the function and variable definitions
  let dates = []
  const addDays = function (days) {
    var date = new Date(this.valueOf());
    date.setDate(date.getDate() + days);
    return date;
  };

  let currentDate = startDate
  if (currentDate.getDay() > 0) currentDate.setDate(currentDate.getDate() - ((currentDate.getDay() + 6) % 7));

  while (currentDate <= endDate) {
    let endWeekDate = addDays.call(currentDate, 7);

    var week = (new Date(currentDate)).getWeek();
    var old_year = currentDate.getFullYear();
    var new_year = endWeekDate.getFullYear();

    var month = currentDate.getMonth() + 1;

    var year = old_year;

    if (week == 1) {
      year = new_year;
    }

    var month_str = month;

    if (month <= 9) month_str = '0' + month;

    var yearmonth = year.toString() + month_str;

    var week_str = week;

    if (week <= 9) week_str = '0' + week;

    dates.push(
        {
          begin: currentDate,
          begin_formatted: getFormattedDate(currentDate),
          end: endWeekDate,
          end_formatted: getFormattedDate(endWeekDate),
          week: week,
          week_str: week_str,
          month: month,
          month_str: month_str,
          monthname: monthnames[month],
          year: year,
          yearmonth: yearmonth
        });
    currentDate = addDays.call(currentDate, 7);
  }
  return dates;
}

const secondsToTime = (seconds) =>{
  return [
      parseInt(seconds / 60 % 60),
      parseInt(seconds % 60)
  ]
    .join(":")
    .replace(/\b(\d)\b/g, "0$1")
}

const customIterator = (array) => {
  let nextIndex = -1;
  return {
      next: () => {
          nextIndex++;
          nextIndex %= array.length;
          return { value: array[nextIndex], done: false };
      },
      nextByProp: (prop1, prop2, value1, value2) => {
        const [first]=  array.filter ((item) => {
            return item[prop1] === value1 && item[prop2] == value2;
        })
        
        
        let index = array.findIndex(item => {
            return first[prop2] === item[prop2] && first[prop1] === item[prop1]
            ;
        });

      
        nextIndex = index;
        nextIndex %= array.length;
        return { value: array[nextIndex], done: false };
      },
      nextSibling: () => {
        let next = nextIndex + 1;
        next %= array.length;
        return { value: array[next], done: false }
      },
      currentRef: () => {
        
        nextIndex %= array.length;
        return { value: array[nextIndex], done: false }
      }
  }
}

const formatTimecode = seconds => {
  return new Date(seconds * 1000).toISOString().substring(14, 19)
}

const removeProperty = (array = [], prop = '') => {
  return array.map(function(item) { 
      delete item[prop]; 
      return item; 
  });
}

const formatPrice = price => {

  return Number(price).toLocaleString("de-DE", {minimumFractionDigits: 2, maximumFractionDigits: 2}) + " &euro;";

}

const formatPseudoPrice = price => {

  if (price > 0) return formatPrice(price);

  return '';

}

const checkIfUrlAndAddParam = (url, param) => {

    var regex = /(?:https?):\/\/(\w+:?\w*)?(\S+)(:\d+)?(\/|\/([\w#!:.?+=&%!\-/]))?/;
    if(!regex .test(url)) {
        //no external URL
        return url;
    }

    url = (url.indexOf("?") != -1 ? url.split("?")[0] + "?" + param + "&" + url.split("?")[1] : (url.indexOf("#") != -1 ? url.split("#")[0] + "?" + param + "#" + url.split("#")[1] : url + '?' + param));

    //console.log(url);

    return url;

}


export {
    getFormattedDate,
    getDates,
    convertDate,
    generateRandomHash,
    isMobileDevice,
    getBreakPoint,
    checkMediaQuery,
    getMediaImg,
    getMaxValue,
    secondsToTime,
    customIterator,
    formatTimecode,
    getPercent,
    removeProperty,
    formatPrice,
    formatPseudoPrice,
    checkIfUrlAndAddParam,
}