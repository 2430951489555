<template>
  <b-container fluid="xxl">


      <b-row>
        <b-col cols="12"   xl="6" xxl="4">

          <h3>{{ $t('NAV_LINK_TEXT_BLOG') }}</h3>
          <template  v-if="articledata.length == 0">
            <p class="mb-5" v-html="$t('NO_ARTICLES_IN_LIST')"></p>
          </template>

         
      </b-col>
    </b-row>
    
    

    <b-row  v-if="articledata.length > 0">

      <!-- item -->
      <b-col
          v-for="(article, article_index) in articledata"
          :key="article_index"
          cols="12" xl="6" xxl="4"
          class="mt-4 mb-3"
      >
        <ProductCard 
          :no-padding="true"
          :product-id="article.article_id  | tostring"
          :product-title="article.title" 
          :product-image="article.image"
          :product-cta-link="`/blogarticle/${article.article_id}`"
          :product-btn-label="$t('READ_MORE')"
          :small-layout="true"
          :product-description="article.excerpt"
          v-on:submit="handleBlogArticleEvent"

        />


      </b-col>

    </b-row>

    
     
  </b-container>
</template>


<script>
    import {mapActions, mapGetters} from "vuex";
    import ProductCard from '@/components/ProductCard.vue';
    import axios from "axios";
    export default {
        name: "areaBlogList",
        components: {
            ProductCard
        },
        data () {
            return {
                articledata: []
            }
        },
        mounted() {
            this.loadBlogData();
            
        },
        computed: {
            ...mapGetters( {
            }),

        },
        methods: {
          ...mapActions([
          ]),

          async loadBlogData() {
            const response = await axios.get('/staticMockData/blogarticles.json');
            this.articledata = response.data.articles;
          },

          async handleBlogArticleEvent(data) {
            if(!data || !("type" in data) ) return;
            const url = data.url;
            if (this.$route.path !== url) this.$router.push(url); 
          },

        },


    }
</script>

<style scoped>

</style>