import axios from "axios";
/*import Vue from "vue";
import router from "@/routes";*/

const state = {

    design: {},
    API_URL: process.env.VUE_APP_API
   
}

const mutations = {

    SET_DESIGN(state, payload) {
        state.design = payload;
       
    },

    UPDATE_COLOR(state, payload) {
        const colors = state.design.colors;
        state.design.colors = colors.map(item => {
            if (item.token === payload.name) {  
                return {...item, value: payload.color};
                
            }
            
            
            return item;
        });
    },

    UPDATE_BORDER(state, payload) {
        const borders = state.design.borders;
        state.design.borders = borders.map(item => {
            if (item.token === payload.name) {  
                return {...item, value: payload.value};
                
            }
            return item;
        });
        
    },


}

const actions = {
    async loadDesign({ commit, /*rootState*/ }) {
        /*var cookie = rootState.getToken;
        const response = await axios.post(
            state.API_URL,
            {
              type: 'get_design',
              cookie: cookie,
            }
        );*/
        const response = await axios.get('./staticMockData/appearance.json');
        if (response.data.error) {

            commit('SET_DESIGN', {})
          } else {
            commit('SET_DESIGN', response.data.themedesign)
          }
    },

    updateColor({commit}, payload) {
        commit('UPDATE_COLOR', payload)
    },

    updateBorder({commit}, payload) {
        commit('UPDATE_BORDER', payload)
    }

   
}



const getters = {

    getColors() {
        return state.design.colors
    },

    getBorderInfo() {
        return state.design.borders
    },

    getLogo() {
        return state.design.logo
    },

}

const desgin = {
    state,
    mutations,
    actions,
    getters

}

export default desgin;