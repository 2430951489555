<template>
    <div class="wl-videoplayer">
        
        <div class="wl-videoplayer__video-inner ">
            <div class="wl-videoplayer__main-video">
                <span class="wl-loader" :class="{'d-none' : isReady}">
                    <span class="wl-loader__holder"></span>
                </span>
                <video :id="`video-${id}`" preload="auto" width="100%" height="100%" ref="player"></video>
            </div>
            
        </div>
    </div>
</template>

<script>

import Vue from 'vue'
import 'mediaelement';
const {MediaElementPlayer} = global;

export default {
    name: "uiVideoPlayer",
    emits: ['ready', 'update', 'played', 'paused', 'ended'],
     data() {
      return {
        playerinstance: null,
        intervalID: 0,
        isReady: false
      }
   },
   props: {
        useCallBack: {
            type: Boolean,
            fedault:false
        },
        refHash: {
            type: String,
            default: ''
        },
        videoid: {
            type: Number,
            default:-1
        },
        interval: {
            type: Number,
            default: 2000
        },
        currenttime: {
            type: Number,
            default: 0
        },
        controls:   {
            type: String,
            default: ''
        },
        video: {
            type: String,
            default: ''
         },
         poster: {
            type: String,
            default: ''
         },
         id: {
            type: String,
            default: () => Vue.utils.generateRandomHash(10)
         },
         shouldPlay:{
            type: Boolean,
            fedault:false
        }
  }, 
  methods: {
    updatePlayer(player) {    
        this.$emit('update', {time:  player.getCurrentTime(), id: this.videoid});
    },
    createPlayer() {
        
        if (!MediaElementPlayer) {
            return;
        }

        
        const { player } = this.$refs;
   
        
        if(this.playerinstance) {
            this.playerinstance.remove();
            this.playerinstance = null;
        }
        
   

        var params = {
           iconSprite: this.controls,
           alwaysShowControls: false,
           videoVolume: 'horizontal',
           autoRewind: false,
           success: (player) => {
            
               
               if(this.useCallBack) {
                
                
                    player.addEventListener('canplay', () => {
                       this.isReady = true;
                   }, false);

                   player.addEventListener('ended', () => {
                       clearInterval(this.intervalID);
                       this.$emit('ended',{time:  player.getCurrentTime(), id: this.videoid});  
                       this.updatePlayer(player) 
                   }, false);

                   player.addEventListener('play', () => {
                   
                       this.intervalID = setInterval(() => {
                           this.updatePlayer(player)
                       }, this.interval);      
                       this.$emit('played');
                   }, false);

                   player.addEventListener('pause', this.pausePlayer, false);
                   player.addEventListener('seeked', () => {
                       if(player.paused) {
                           clearInterval(this.intervalID);
                       }
                       
                       this.updatePlayer(player)
                   }, false);
               }
               
            }, 
        };

        this.playerinstance = new MediaElementPlayer(player, params);
        this.startPlayer();
       
    },

    setPause(){
       
        const { player } = this.$refs;
        player.pause();
        if(this.useCallBack) {
            clearInterval(this.intervalID);
            this.$emit('paused',{time:  player.getCurrentTime(), id: this.videoid});   
        }
        
    },

    pausePlayer(){
        if(this.useCallBack) {
            clearInterval(this.intervalID);
            const { player } = this.$refs;
            this.$emit('paused',{time:  player.getCurrentTime(), id: this.videoid});   
        }
    },
    startPlayer() {
       
        this.playerinstance.setPoster( this.poster);
        this.playerinstance.setSrc( this.video);
        this.playerinstance.load();  
        this.playerinstance.setCurrentTime( this.currenttime) 
        
        this.$emit('ready'); 
        if(this.shouldPlay)  {
            this.isReady = false;
            this.playerinstance.play()
           
        } else {
            this.isReady = true;
        }
        
            
    }
  },
  mounted() {
    this.createPlayer();
  },
   watch: {
    refHash(newVal, oldVal) {
      if(newVal !== oldVal) {
        this.startPlayer();
      }
    },
  },
  beforeDestroy() {
    
    if(this.playerinstance) {
        this.updatePlayer(this.playerinstance);
        this.$refs.player.removeEventListener('pause', this.pausePlayer);
        this.playerinstance.setSrc('');        
        this.playerinstance.remove();
    }
    clearInterval(this.intervalID);
  }

}
</script>
