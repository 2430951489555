<template>
  <div>
  <b-container fluid="xxl">
    
      <b-row class="mb-4">
        <b-col cols="12" >
          <b-button
            type="button"
            variant="cta"
            class="button-cta rounded-circle icon"
            :to="'/myChecklistProjects/' + productId"
            @click="$root.$emit('track', {source: null, target: null, button: 'Checkliste zurück zur Übersicht'})"
        ><font-awesome-icon icon="fa-solid fa-caret-left" /></b-button>
        
        </b-col>
       
      </b-row>

    
      <b-row class="mb-4">
        <b-col cols="12" md="8" >
          <div class="d-flex align-items-center">

            <div class="mr-4">
              <b-button
                  type="button"
                  variant="cta"
                  class="button-cta rounded-circle icon small"
                  @click="editChecklistTitle()"
              >
              <font-awesome-icon :icon="(isEditChecklistTitle) ? 'fa-solid fa-check': 'fa-solid fa-pencil'" /></b-button>
            </div>
            
            <div>
              <h6 class="font-light">{{ productTitle }}</h6> 
              <h3 class="mb-0" v-if="!isEditChecklistTitle">{{ projectTitle }}</h3>
              <div v-else>
                <b-input-group>

                  <b-form-input
                      name="checklistTitle"
                      id="checklistTitle"
                      type="text"
                      placeholder=""
                      v-model.trim="projectTitle"
                  ></b-form-input>

                  </b-input-group>
              </div>
            </div>
          </div>

          
        </b-col>
      </b-row>
     

      <b-row class="mb-7">
        <b-col cols="12" md="8" >
          <p>Wie Du Deine Checkliste richtig verwalten kannst, erklären wir Dir hier.</p>
          <p><b-button
              type="button"
              variant="cta"
              v-b-modal="'video-anleitung'"
              @click="$root.$emit('track', {source: null, target: null, button: 'Checkliste Video-Anleitung'})"
          >
          {{ $t('QUICK_TIP') }}
        </b-button></p>

        <b-modal id="video-anleitung" size="lg"  :centered="true" :hide-footer="true" :title="$t('QUICK_TIP_TITLE')">
          <div class="wl-external-video">
              <div class="wl-external-video__inner">
                  <div class="wl-external-video__main">
                      <div><iframe src="https://player.vimeo.com/video/775124521?h=b0800454aa&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen style="position:absolute;top:0;left:0;width:100%;height:100%;" title="checklisten-overview.mov"></iframe>
                  </div>

              </div>
            </div>
          </div>

          <!--<div style="padding:75% 0 0 0;position:relative;border-radius: 15px;"><iframe src="https://player.vimeo.com/video/775124521?h=b0800454aa&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen style="position:absolute;top:0;left:0;width:100%;height:100%;" title="checklisten-overview.mov"></iframe></div>-->
        </b-modal>
        </b-col>
      </b-row>
    
      <b-row>
        <b-col cols="12">
          <hocCard :no-padding="true">
            <template #default>
              <b-container  fluid="xxl" class="p-0 wl-checklist mb-xl-0 mb-6">
                  <div v-for="( main_category, main_index) in getChecklist.checklist_data"  :key="main_index">
                    

                    <b-row
                        v-if="main_index == 0"
                        class="wl-checklist__header m-0 align-items-center">

                      <b-col cols="3">
                        <p class="h6">
                          {{ $t('AREA') }}
                        </p>
                      </b-col>

                      <b-col cols="1">
                        <p class="h6 text-center">
                          {{ $t('TOTAL') }}
                        </p>
                      </b-col>

                      <b-col cols="2">
                        <p class="h6 text-center">
                          {{ $t('OPTIMIZE') }}
                        </p>
                      </b-col>

                      <b-col cols="1">
                        <p class="h6 text-center">
                          {{ $t('TODO') }}
                        </p>
                      </b-col>

                      <b-col cols="1">
                        <p class="h6 text-center">
                          {{ $t('DONE') }}
                        </p>
                      </b-col>

                      <b-col cols="2">
                        <p class="h6">
                          {{ $t('STATUS') }}
                        </p>
                      </b-col>

                      <b-col cols="2">
                        <p class="h6">
                          {{ $t('IMPORTANCE') }}
                        </p>
                      </b-col>

                    </b-row>

                    <b-row class="m-0 wl-checklist__maincategory align-items-center pb-3 pt-3 pt-xl-0  pb-xl-0"  v-b-toggle="`row-cild-${main_index}`">
                    

                        <b-col cols="12" xl="3">
                          <div class="wl-checklist__headline d-flex align-items-center pr-6 pr-xl-0 mb-3 mb-xl-0"><span class="bg-dark rounded-circle mr-2">{{main_index +1}}</span> {{ main_category.category_title }}</div>
                        </b-col>

                        <b-col cols="12" xl="1" class="d-flex d-xl-block">
                          <p class="h6  mb-0 d-xl-none d-block">
                            {{ $t('TOTAL') }}
                          </p>
                          <p class="h6 text-center ml-auto">
                            {{ main_category.statistic.sum }}
                          </p>
                        </b-col>

                        <b-col cols="12" xl="2" class="d-flex d-xl-block">
                          <p class="h6  mb-0 d-xl-none d-block">
                            {{ $t('OPTIMIZE') }}
                          </p>
                          <p class="h6 text-center ml-auto">
                            {{ main_category.statistic.optimizable }}
                          </p>
                        </b-col>

                        <b-col cols="12" xl="1" class="d-flex d-xl-block">
                          <p class="h6  mb-0 d-xl-none d-block">
                            {{ $t('TODO') }}
                          </p>
                          <p class="h6 text-center ml-auto">
                            {{ main_category.statistic.todo }}
                          </p>
                        </b-col>

                        <b-col cols="12" xl="1" class="d-flex d-xl-block">
                          <p class="h6  mb-0 d-xl-none d-block">
                            {{ $t('DONE') }}
                          </p>
                          <p class="h6 text-center ml-auto">
                            {{ main_category.statistic.done }}
                          </p>
                        </b-col>

                        <b-col cols="12" xl="2" class="d-flex d-xl-block align-items-center mt-2 mt-xl-0">
                          <p class="h6  mb-0 d-xl-none d-block ">
                            {{ $t('STATUS') }}
                          </p>
                          <p class="h6 ml-auto mw-50">
                            <uiProgessbar :variant="getProgressCssStatus('success', main_category.statistic.status)" variant-bg="light" :currentvalue="parseFloat(main_category.statistic.status)" :totalvalue="parseInt(100)"/>
                          </p>
                        </b-col>

                        <b-col cols="12" xl="2" class="d-flex d-xl-block align-items-center">
                          <p class="h6  mb-0 d-xl-none d-block ">
                            {{ $t('IMPORTANCE') }}
                          </p>
                          <p class="h6 ml-auto mw-50">
                            <uiProgessbar :variant="getProgressCss('primary', main_category.category_weight)" variant-bg="light" :currentvalue="parseInt(main_category.category_weight)" :totalvalue="parseInt(max)"/>
                            
                          </p>
                        </b-col>
                    </b-row>

                    <!-- sub-category -->

                    <b-collapse :id="`row-cild-${main_index}`" class="w-100">

                      <div
                          v-for="(sub_category, sub_index) in main_category.sub_categories"
                          :key="sub_index" class="w-100"
                      >
                        <b-row class="m-0 wl-checklist__subcategory align-items-center pb-3 pt-3 pt-xl-0 pb-xl-0">

                          <b-col cols="12" xl="3">
                            <div class="wl-checklist__subheadline">
                              <font-awesome-icon
                                  :icon="['fa-solid', sub_category.category_icon]"
                                  class="mr-1
                                  "
                              />
                              {{ sub_category.category_title }}
                            </div>
                          </b-col>

                          <b-col cols="12" xl="1"  class="d-flex d-xl-block">
                            <p class="h6  mb-0 d-xl-none d-block">
                              {{ $t('TOTAL') }}
                            </p>
                            <p class="h6 text-center ml-auto">
                              {{ sub_category.statistic.sum }}
                            </p>
                          </b-col>

                          <b-col cols="12" xl="2" class="d-flex d-xl-block">
                            <p class="h6  mb-0 d-xl-none d-block">
                              {{ $t('OPTIMIZE') }}
                            </p>
                            <p class="h6 text-center ml-auto">
                              {{ sub_category.statistic.optimizable }}
                            </p>
                          </b-col>

                          <b-col cols="12" xl="1" class="d-flex d-xl-block">
                            <p class="h6  mb-0 d-xl-none d-block">
                              {{ $t('TODO') }}
                            </p>
                            <p class="h6 text-center ml-auto">
                              {{ sub_category.statistic.todo }}
                            </p>
                          </b-col>

                          <b-col cols="12" xl="1" class="d-flex d-xl-block">
                            <p class="h6  mb-0 d-xl-none d-block">
                              {{ $t('DONE') }}
                            </p>
                            <p class="h6 text-center ml-auto">
                              {{ sub_category.statistic.done }}
                            </p>
                          </b-col>

                          <b-col cols="12" xl="2"  class="d-flex d-xl-block align-items-center mt-2 mt-xl-0">
                            <p class="h6  mb-0 d-xl-none d-block ">
                              {{ $t('STATUS') }}
                            </p>
                            <p class="h6 ml-auto mw-50">
                              <uiProgessbar :variant="getProgressCssStatus('success',sub_category.statistic.status)"  variant-bg="light" :currentvalue="sub_category.statistic.status" :totalvalue="parseInt(100)"/>
                            </p>
                          </b-col>

                          <b-col cols="2" class="d-xl-block d-none">
                            <p class="h6">

                            </p>
                          </b-col>


                        </b-row>

                        <!-- items -->

                        <div
                            v-for="(item, item_index) in sub_category.items"
                            :key="item_index"
                        >
                          <b-row
                              v-if="item_index == 0"
                              class="m-0 wl-checklist__subtask"
                          >

                            <b-col cols="4">
                              <p class="h6">
                                {{ $t('TASK') }}
                              </p>
                            </b-col>

                            <b-col cols="1">
                              <p class="h6 text-center">
                                {{ $t('INFO') }}
                              </p>
                            </b-col>

                            <b-col cols="4">
                              <p class="h6">
                                {{ $t('ASSESSMENT') }}
                              </p>
                            </b-col>

                            <b-col cols="1">
                              <p class="h6">
                                {{ $t('BENEFIT') }}
                              </p>
                            </b-col>

                            <b-col cols="1">
                              <p class="h6">
                                {{ $t('EFFORT') }}
                              </p>
                            </b-col>

                            <b-col cols="1">
                              <p class="h6">
                                {{ $t('PRIORITY') }}
                              </p>
                            </b-col>

                          </b-row>


                          <b-row class="m-0 wl-checklist__tasks align-items-center" >
                                <b-col cols="12" xl="4" class="mb-3 mb-xl-0">
                                <p class="h6 mb-0 d-xl-none d-block">
                                  {{ $t('TASK') }}
                                </p>
                                <p class="d-flex  align-items-start">
                                  <uiCheck :class="'mr-3'" :checked="Boolean(parseInt(getChecklist.checklist_data[main_index].sub_categories[sub_index].items[item_index].is_done))" :id="`isdone-${main_index}-${sub_index}-${item_index}`" v-on:change="isDone" :context="{mainindex: main_index, subindex: sub_index, itemindex: item_index}" />
                                  {{ item.item_description }}
                                </p>
                            </b-col>

                            <b-col cols="12" xl="1"  class="d-flex d-xl-block">
                              <p class="h6 mb-0 d-xl-none d-block" v-if="item.item_example != ''">
                                {{ $t('INFO') }}
                              </p>
                              <p v-if="item.item_example != ''" class="text-center ml-auto">
                                <b-button
                                      type="button"
                                      variant="cta"
                                      class="button-cta rounded-circle icon small mr-2 d-inline-block"
                                      @click="preview(item.product_checklist_item_id, item.item_description)"
                                  >
                                  <font-awesome-icon  :icon="['fa-solid', 'fa-circle-info']"/></b-button>
                              </p>

                            </b-col>

                            <b-col cols="12" xl="4" class="d-flex d-xl-block mt-2 mt-xl-0">
                              <p class="h6  mb-0 d-xl-none d-block">
                                {{ $t('ASSESSMENT') }}
                              </p>
                              <div class="ml-auto">
                                <uiGroupSelect v-on:change="saveEvaluationId" :context="{mainindex: main_index, subindex: sub_index, itemindex: item_index}" :id="`evaluationgroup-${main_index}-${sub_index}-${item_index}`" :options="createEvaluationOptions(item.evaluation_color, `evaluationgroup-${main_index}-${sub_index}-${item_index}`)"></uiGroupSelect>
                              </div>
                            </b-col>

                            <b-col cols="12" xl="1" class="d-flex d-xl-block align-items-center mt-3 mt-xl-0">
                              <p class="h6 mb-0 d-xl-none d-block">
                                  {{ $t('BENEFIT') }}
                              </p>
                              <p class="h6 ml-auto mw-50">
                                <uiProgessbar :variant="getProgressCss('secondary', item.item_impact)"  :currentvalue="parseInt(item.item_impact)" :totalvalue="parseInt(max)"/>
                              </p>
                            </b-col>

                            <b-col cols="12" xl="1" class="d-flex d-xl-block align-items-center mt-2 mt-xl-0">
                              <p class="h6 mb-0 d-xl-none d-block">
                                  {{ $t('EFFORT') }}
                              </p>
                              <p class="h6 ml-auto mw-50">
                                <uiProgessbar :variant="getProgressCss('cta', item.item_cost)" :currentvalue="parseInt(item.item_cost)" :totalvalue="parseInt(max)"/>
                              </p>
                            </b-col>

                            <b-col cols="12" xl="1" class="d-flex d-xl-block align-items-center mt-2 mt-xl-0">
                              <p class="h6 mb-0 d-xl-none d-block">
                                  {{ $t('PRIORITY') }}
                              </p>
                              <p class="h6 ml-auto mw-50">
                                <uiProgessbar :variant="getProgressCss('primary', item.item_priority)" :currentvalue="parseInt(item.item_priority)" :totalvalue="parseInt(max)"/>
                              </p>
                            </b-col>

                            


                          </b-row>

                          <b-row class="wl-checklist__notice">
                            <b-col cols="12">
                               
                                <div class="pr-2 pl-2 d-flex align-items-center mb-3 mb-xl-2">
                                  
                                  <b-button
                                      type="button"
                                      variant="cta"
                                      class="button-cta rounded-circle icon small mr-2"
                                    @click="editNotice(main_index, sub_index, item_index)"
                                  >
                                  <font-awesome-icon :icon="(item.is_edit) ? 'fa-solid fa-check': 'fa-solid fa-pencil'" /></b-button>
                                  <p class="ml-1 font-weight-bold">{{ $t('NOTES') }} </p>
                                </div>
                                <div v-if="item.notice != '' && !item.is_edit" class="text-left pt-0 pr-3 pb-3 pl-3">
                                  <small>{{ item.notice }}</small>
                                </div>

                                <div v-else>
                                  <div
                                    v-if="item.is_edit">
                                    <!--
                                    v-model.lazy="getChecklist.checklist_data[main_index].sub_categories[sub_index].items[item_index].notice"
                                    -->
                                    <b-form-textarea
                                        name="notice"
                                        :placeholder="$t('PLACEHOLDER_NOTICE')"
                                        :value="getChecklist.checklist_data[main_index].sub_categories[sub_index].items[item_index].notice"
                                        @change="v => getChecklist.checklist_data[main_index].sub_categories[sub_index].items[item_index].notice = v"
                                        rows="4"
                                    ></b-form-textarea>

                                  </div>
                                </div>

                                <hr/>
                            </b-col>
                          </b-row>

                        </div>

                      </div>
                    </b-collapse>
                  </div>

                  <b-row
                      v-if="isCheckListAvailable"
                      class="wl-checklist__footer m-0 align-items-center pb-3 pt-3 pt-xl-0 pb-xl-0">

                    <b-col cols="12" xl="3">
                      <p class="h6 d-xl-block d-none">
                        {{ $t('TOTAL') }}
                      </p>
                    </b-col>

                    <b-col cols="12" xl="1" class="d-flex d-xl-block">
                      <p class="h6  mb-0 d-xl-none d-block">
                        {{ $t('TOTAL') }}
                      </p>
                      <p class="h6 text-center ml-auto">
                        {{ getChecklist.statistic.sum }}
                      </p>
                    
                    </b-col>

                    <b-col cols="12" xl="2" class="d-flex d-xl-block">
                      <p class="h6  mb-0 d-xl-none d-block">
                        {{ $t('OPTIMIZE') }}
                      </p>
                      <p class="h6 text-center ml-auto">
                        {{ getChecklist.statistic.optimizable }}
                      </p>
                    </b-col>

                    <b-col cols="12" xl="1" class="d-flex d-xl-block">
                      <p class="h6  mb-0 d-xl-none d-block">
                        {{ $t('TODO') }}
                      </p>
                      <p class="h6 text-center ml-auto">
                        {{ getChecklist.statistic.todo }}
                      </p>
                    </b-col>

                    <b-col cols="12" xl="1" class="d-flex d-xl-block">
                      <p class="h6  mb-0 d-xl-none d-block">
                        {{ $t('DONE') }}
                      </p>
                      <p class="h6 text-center ml-auto">
                        {{ getChecklist.statistic.done }}
                      </p>
                    </b-col>

                    <b-col cols="12" xl="2" class="d-flex d-xl-block align-items-center mt-2 mt-xl-0">
                      <p class="h6  mb-0 d-xl-none d-block ">
                        {{ $t('STATUS') }}
                      </p>
                      <p class="h6 ml-auto mw-50">
                        <uiProgessbar :variant="getProgressCssStatus('success',getChecklist.statistic.status)" :currentvalue="parseFloat(getChecklist.statistic.status)" :totalvalue="parseInt(100)"/>
                      </p>
                  
                    </b-col>

                    <b-col cols="12" xl="2">
                      <p class="h6">

                      </p>
                    </b-col>


                  </b-row>

                  <b-modal size="xl" id="preview-modal" hide-footer :title="previewModalTitle">
                    <div class="d-block text-center">
                      <p v-html="previewModalContent"></p>
                    </div>
                  </b-modal>
                  
                </b-container>


              

            </template>               
          </hocCard>
        </b-col>
      </b-row>
    </b-container>

    <b-container fluid="xxl" v-if="upselling.length > 0">

                  <b-row class="justify-content-md-center mb-3 mt-8">
                    <b-col cols="12" md="auto">

                      <h3>{{$t('PRODUCT_RECOMMONDATIONS')}}</h3>

                      <b-row>
                      <!--{{upselling}}-->
                        <b-col
                          v-for="(product, product_index)  in upselling"
                          :key="product_index"
                          cols="12" md="6" cs="4" xxl="3"
                          class="mt-4 mb-3"
                          >
                            <ProductCard 
                              :no-padding="true"
                              :product-id="product.product_id  | tostring"
                              :product-title="product.product_name" 
                              :product-description="product.product_description_short"
                              :product-type="product.product_type"
                              :product-image="$utils.getMediaImg(3, product.media, 'product_media_description_type_id')"

                              :small-layout="true"
                              :product="product"
                              :badge-text="badgeTextHandler(product)"
                              :product-price="`${product.product_price}`"
                              :product-pseudo-price="`${product.product_pseudoprice}`"
                              :product-btn-label="handleSliderProductBtnLabel(product)"
                              :product-cta-link="product.product_url"

                              v-on:submit="handleSliderProductCardEvent"

                            />


                          </b-col>
                      </b-row>

                    </b-col>
                  </b-row>

      <b-modal size="xl" ref="promotion-modal" hide-footer :title="promotionModalTitle">
        <div class="d-block text-left">
          <p v-html="promotionModalContent"></p>
        </div>
      </b-modal>

                </b-container>

 </div>
</template>

<script>

import {mapActions, mapGetters, mapMutations} from "vuex";
import hocCard from '@/components/hoc/hocCard';
import uiProgessbar from '@/components/ui/uiProgressbar.vue';
import axios from "axios";
import uiGroupSelect from "./ui/uiGroupSelect.vue";
import uiCheck from "./ui/uiCheck.vue";
import ProductCard from '@/components/ProductCard.vue';
import Vue from "vue";

export default {
  name: "areaMyChecklistOverview",
  components: {
    hocCard,
    uiProgessbar,
    uiGroupSelect,
    uiCheck,
    ProductCard
},
  data() {
    return {

      API_URL: process.env.VUE_APP_API,
      checklist: {},
      checklistEvaluation: [],
      upselling: [],
      previewModalTitle: '',
      previewModalContent: '',

      max: 10,
      productId: undefined,
      productTitle: '',
      projectTitle: '',

      companyCheckListID: -1,

      isEditChecklistTitle: false,

      activeMainIndex: [],

      notice: '',

      promotionModalTitle: '',
      promotionModalContent: '',

    }
  },
  mounted () {

    if (!this.$route.params.company_checklist_id) {
        //console.log('id nicht gesetzt = route zu meinen Produkten');
        this.$router.push('/myProducts');
        
    } else {
      this.companyCheckListID = this.$route.params.company_checklist_id;
    }

    this.loadCompanyChecklist();
    this.loadChecklistItemEvaluation();

  },
  created() {

  },
  computed: {
    ...mapGetters({
        getChecklist : 'getChecklistItems',
        getTokenParamWithTokenMd5: 'getTokenParamWithTokenMd5',
      }),
      isCheckListAvailable () {
        return this.getChecklist.checklist_data?.length > 0
      }
  },
  methods: {
    ...mapMutations([
    ]),
    ...mapActions([
      'setProductList',
      'setNewProduct',
      'addToProductList',
      'setProductMediaItem',
      'setProductMedia',
      'setProductMediaDescriptionTypes',
    ]),
    ...mapGetters([

    ]),


    createTag(media) {

      if(media.media_type_description === undefined) return '';

      var str = '';

      switch(media.media_type_description) {

        case 'Bild':
          str += '<img src="' + media.url + '" width="100" border="0"/>';
          break;

        case 'Dokument':
          str += '';
          break;

        case 'Vimeo':
          //str = '<iframe src="http://player.vimeo.com/video/' + media.filename_no_extension + '" width="100%" frameborder="0" allow="autoplay; fullscreen" allowfullscreen></iframe>';

          str = '<div style="padding:51.3% 0 0 0;position:relative;"><iframe src="https://player.vimeo.com/video/' + media.filename_no_extension + '?h=1b6c086b95&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen style="position:absolute;top:0;left:0;width:100%;height:100%;" title="Back to Top Link"></iframe></div>';
          break;

        case 'Youtube':
          str = '<iframe width="100%" src="https://www.youtube-nocookie.com/embed/' + media.filename_no_extension + '" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>';
          break;

        case 'URL':
          str += "";
          break;

      }

      //console.log(str);

      return str;

    },

    handleFreeProductCardEvent(data) {
      if(!data || !("type" in data) )
        return;
      switch(data.type) {
        case 'Whitepaper':
          window.location.href = data.url;
          break;

        case 'Checkliste':
          this.$router.push(data.url);
          break;
      }
    },
    async loadCompanyChecklist() {

      //todo: local development
      var cookie = this.$store.getters.getToken;

      const response = await axios.post(
          this.API_URL,
          {
            type: 'get_company_checklist',
            cookie: cookie,
            company_checklist_id: this.companyCheckListID,
          }
      );



      if (!response.data.error) {
            this.$store.dispatch('setChecklistItems', response.data);
            const {checklist, product, checklist_data} = this.getChecklist;

            this.checklist = checklist_data;

            this.productId = checklist?.product_id

            this.productTitle =product?.product_name;

            this.projectTitle = checklist?.checklist_name;

            this.companyCheckListID =checklist?.company_checklist_id;

            this.upselling =  product?.upselling;

      }

    },
    async loadChecklistItemEvaluation() {

      //todo: local development
      var cookie = this.$store.getters.getToken;

      const response = await axios.post(
          this.API_URL,
          {
            type: 'get_checklist_item_evaluation',
            cookie: cookie,
          }
      );

      //console.log(response);

      if (!response.data.error) {
        this.checklistEvaluation = response.data;

      }

    },
    getProgressCss(variant, value) {

      if (value <= 3 ) {
         return `${variant}300`
      }

      if (value <= 6) {
         return `${variant}600`
      }

      return variant;

    },
    getProgressCssStatus(variant, value) {

      if (value <= 33) {
        return `${variant}300`
      }

      if (value <= 66) {
        return `${variant}600`
      }

       return variant;

    },
   createEvaluationOptions(currentvalue, groupindex = -1) {
      let evaluation = this.checklistEvaluation;
      let options = [];
      
      Object.keys(evaluation).forEach(index => {
        let obj = {};
        
        let variant= '';
        let value= '';
        switch(index.toString()) {
            case '0':
            variant = 'success'
            value = 'green'
            break;

            case '1':
            variant = 'info';
            value = 'yellow';
            break;
            
            case '2':
            variant = 'danger';
            value = 'red';
            break;

            case '3':
            variant = 'grey';
            value = 'grey';
            break;

            default: 
              variant = 'grey'
              value = 'grey'
            break;
        }
        obj = {...obj, ...{
          text: evaluation[index].evaluation_description, 
          value:  evaluation[index].product_checklist_item_evaluation_id,
          variant: variant,
          selected: (currentvalue == value),
          group: groupindex
        }};
        
        options = [...options, obj];

      });

      return options;
    },
    async preview(product_checklist_item_id, title) {
      //console.log('preview');

      //todo: local development
      var cookie = this.$store.getters.getToken;
     
      const response = await axios.post(
          this.API_URL,
          {
            type: 'get_preview',
            product_id: this.productId,
            product_checklist_item_id: product_checklist_item_id,
            cookie: cookie
          }
      );

      //console.log(response.data);

      this.previewModalTitle = title;
      this.previewModalContent = response.data;
      
      this.$bvModal.show('preview-modal');
    },
    async editChecklistTitle() {

      if (!this.isEditChecklistTitle) {
        this.isEditChecklistTitle = true;
      } else {
        this.isEditChecklistTitle = false;

        //todo: local development
        var cookie = this.$store.getters.getToken;

        await axios.post(
            this.API_URL,
            {
              type: 'update_company_checklist_name',
              company_checklist_id:  this.companyCheckListID,
              checklist_name: this.projectTitle,
              cookie: cookie,
            }
        );

      }

    },
    async saveEvaluationId(data) {

   
      const {value, context} = data;
      const entry = this.checklist[context.mainindex].sub_categories[context.subindex].items[context.itemindex];
      const item_id = entry.company_checklist_item_id;
      const evaluation_id = value;


      for (const evaluation of this.checklistEvaluation) {
        
        if (evaluation.product_checklist_item_evaluation_id == evaluation_id) {
          
          entry.evaluation_color = evaluation.evaluation_color;
        }

      }

      //todo: local development
      const cookie = this.$store.getters.getToken;

      await axios.post(
          this.API_URL,
          {
            type: 'update_company_checklist_item_evaluation',
            company_checklist_item_id: item_id,
            product_checklist_item_evaluation_id: evaluation_id,
            cookie: cookie,
          }
      );

      this.loadCompanyChecklist();
    },
    async editNotice(main_index, sub_index, item_index) {

      var item = this.checklist[main_index].sub_categories[sub_index].items[item_index];
      var notice = item.notice;

      if (!item.is_edit) {
        this.checklist[main_index].sub_categories[sub_index].items[item_index].is_edit = true;
      } else {
        this.checklist[main_index].sub_categories[sub_index].items[item_index].is_edit = false;

        //todo: local development
        var cookie = this.$store.getters.getToken;

        await axios.post(
            this.API_URL,
            {
              type: 'update_company_checklist_item_notice',
              company_checklist_item_id: item.company_checklist_item_id,
              notice: notice,
              cookie: cookie,
            }
        );

      }

    },
    async isDone(data) {
      const {context} = data;
      const entry = this.checklist[context.mainindex].sub_categories[context.subindex].items[context.itemindex];

      //console.log(entry);

      if (entry.is_done == 1) {
        entry.is_done = 0;
      } else {
        entry.is_done = 1;
      }

      //console.log(entry.is_done);

      //todo: local development
      var cookie = this.$store.getters.getToken;

      await axios.post(
          this.API_URL,
          {
            type: 'update_company_checklist_item_is_done',
            company_checklist_item_id: entry.company_checklist_item_id,
            is_done: entry.is_done,
            cookie: cookie,
          }
      );

      this.loadCompanyChecklist();

    },

    async handleSliderProductCardEvent(data) {
      if(!data || !("type" in data) ) {
        return;
      }

      var url = '';
      var purl = '';
      //console.log(data);
      //console.log(data.type);

      switch(data.type) {

        case 'Whitepaper':
          await this.$root.$emit('track', {source: null, target: data.product['whitepaper'].media[0].url, button: data.type + ': ' + data.product.product_name});
          //console.log(data.product['whitepaper'].media[0].url);
          this.trackSale(data.product.product_id, data.product['whitepaper'].media[0].url);
          break;

        case 'Checkliste':

          if (("product" in data)
              && data.product.product_price > 0
          ) {
            await this.$root.$emit('track', {source: null, target: data.product.product_url, button: data.type + ': ' + data.product.product_name});
            //zur Kaufseite
            purl = this.$utils.checkIfUrlAndAddParam(data.product.product_url, this.getTokenParamWithTokenMd5);
            window.open(purl,'_blank');
          } else {
            console.log(data);
            url = '/myChecklistProjects/' + data.product.product_id;
            await this.$root.$emit('track', {source: null, target: url, button: data.type + ': ' + data.product.product_name});
            //Gratisliste erwerben
            this.trackSaleChecklistUpselling(data.product.product_id, url);
          }
          break;

        case 'Onlinekurs':
          if (("product" in data)
              && data.product.product_price > 0
          ) {
            await this.$root.$emit('track', {source: null, target: data.product.product_url, button: data.type + ': ' + data.product.product_name});
            //zur Kaufseite
            purl = this.$utils.checkIfUrlAndAddParam(data.product.product_url, this.getTokenParamWithTokenMd5);
            window.open(purl,'_blank');
          } else {
            url = '/academylesson/' + data.product.product_id;
            await this.$root.$emit('track', {source: null, target: url, button: data.type + ': ' + data.product.product_name});
            //Gratis erwerben
            this.trackSaleCourse(data.product.product_id, url);
          }
          break;

        case 'Support':
        case 'Mastermind':
          /*
          if (("product" in data)
              && data.product.product_price > 0
          ) {
            await this.$root.$emit('track', {source: null, target: data.product.product_url, button: data.type + ': ' + data.product.product_name});
            //zur Kaufseite
            purl = this.$utils.checkIfUrlAndAddParam(data.product.product_url, this.getTokenParamWithTokenMd5);
            window.open(purl,'_blank');
          }

           */

          if (("product" in data)) {

            if (data.product.product_is_promotionpage) {

              await this.$root.$emit('track', {source: null, target: 'promotionpage', button: data.type + ': ' + data.product.product_name});

              this.promotion(data.product.product_id);

            } else if (data.product.product_url != '' && data.product.product_url != null) {

              await this.$root.$emit('track', {source: null, target: data.product.product_url, button: data.type + ': ' + data.product.product_name});
              //zur Kaufseite
              purl = this.$utils.checkIfUrlAndAddParam(data.product.product_url, this.getTokenParamWithTokenMd5);
              window.open(purl,'_blank');

            }
          }
          break;
      }


    },
    async promotion(product_id) {

      //console.log(item);

      //todo: local development
      var cookie = this.$store.getters.getToken;

      const response = await axios.post(
          this.API_URL,
          {
            type: 'get_preview_promotion',
            product_id: product_id,
            cookie: cookie
          }
      );

      //console.log(response.data);

      this.promotionModalTitle = response.data.product_promotion_title;
      this.promotionModalContent = response.data.product_promotion_description;

      this.$refs['promotion-modal'].show();
    },

    badgeTextHandler(product) {

      //console.log(product);

      var label = '';

      if (product.product_price > 0) {
        label = Vue.utils.formatPrice(product.product_price);
      } else {
        label = this.$t('FREE');
      }

      return label;

    },

    handleSliderProductBtnLabel(product) {

      //console.log(product);

      var label = '';

      switch(product.product_type) {
        case 'Whitepaper':
          label = this.$t('FREE_OF_CHARGE_DOWNLOAD');
          break;

        case 'Checkliste':
          if (product.product_price > 0) {
            /*
            label = '';
            if (product.product.product_pseudoprice > 0) {
              label = "<s>" + Vue.utils.formatPrice((product.product.product_pseudoprice)) + "</s>";
            }
            label += Vue.utils.formatPrice((product.product.product_price));

             */
            label = this.$t('MORE_DETAILS');
          } else {
            label = this.$t('FREE_OF_CHARGE_ACCESS');
          }
          break;

        case 'Onlinekurs':
          if (product.product_price > 0) {
            /*
            label = '';
            if (product.product.product_pseudoprice > 0) {
              label = "<s>" + Vue.utils.formatPrice((product.product.product_pseudoprice)) + "</s>";
            }
            label += Vue.utils.formatPrice((product.product.product_price));

             */
            label = this.$t('MORE_DETAILS');
          } else {
            label = this.$t('GO_TO_LEARNING_CONTENT');
          }
          break;

        case 'Support':
          label = this.$t('MORE_DETAILS');
          break;

        case 'Mastermind':
          label = this.$t('MORE_DETAILS');
          break;


      }

      //console.log(label, product.product_name);

      return label;


    },

    async trackSale(product_id, url) {

      //todo: local development
      var cookie = this.$store.getters.getToken;

      await axios.post(
          this.API_URL,
          {
            type: 'track_sale',
            cookie: cookie,
            product_id: product_id,
          }
      );

      this.$store.dispatch('updateMyProductIsMyProduct', product_id);
      this.emitChanges = true;
      //console.log(url);
      var purl = this.$utils.checkIfUrlAndAddParam(url, this.getTokenParamWithTokenMd5);
      window.location = purl;


    },

    async trackSaleChecklistUpselling(product_id, url) {

      //todo: local development
      var cookie = this.$store.getters.getToken;

      await axios.post(
          this.API_URL,
          {
            type: 'track_sale_checklist',
            cookie: cookie,
            product_id: product_id,
          }
      );

      this.$store.dispatch('updateMyProductIsMyProduct', product_id);
      //this.$router.push('/myChecklistProjects/' + product_id);
      this.$router.push(url);

    },

    async trackSaleCourse(product_id, url) {

      //todo: local development
      var cookie = this.$store.getters.getToken;

      await axios.post(
          this.API_URL,
          {
            type: 'track_sale_course',
            cookie: cookie,
            product_id: product_id,
          }
      );

      //this.$router.push('/academylesson/' + product_id);
      this.$router.push(url);

    },

  }
}
</script>

<style scoped>

</style>