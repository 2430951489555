const state = {

    is_logged_id: false,
    user: {},
    company: {},
    token: '',
    tokenMd5: '',
    tokenParam: 'et=',
    userTypes: {},
    systemInfo: {},
    fromRoute: {},
    toRoute: {},
}

const mutations = {

    SET_IS_LOGGED_IN(state, payload) {
        state.is_logged_id = payload;
    },

    SET_USER(state, payload) {
        state.user = payload;
    },

    SET_COMPANY(state, payload) {
        state.company = payload;
    },

    SET_TOKEN(state, payload) {
        state.token = payload;
    },

    SET_TOKEN_MD5(state, payload) {
        state.tokenMd5 = payload;
    },

    SET_USER_TYPES(state, payload) {
        state.userTypes = payload;
    },

    SET_SYSTEM_INFO(state, payload) {
        state.systemInfo = payload;
    },

    SET_FROM_ROUTE(state, payload) {
        state.fromRoute = payload;
    },

    SET_TO_ROUTE(state, payload) {
        state.toRoute = payload;
    },

}

const actions = {

    setIsLoggedIn({commit}, payload) {
        commit('SET_IS_LOGGED_IN', payload);
    },

    setUser({commit}, payload) {
        commit('SET_USER', payload);
    },

    setCompany({commit}, payload) {
        commit('SET_COMPANY', payload);
    },

    setToken({commit}, payload) {
        commit('SET_TOKEN', payload);
    },

    setTokenMd5({commit}, payload) {
        commit('SET_TOKEN_MD5', payload);
    },

    setUserTypes({commit}, payload) {
        commit('SET_USER_TYPES', payload);
    },

    setSystemInfo({commit}, payload) {
        commit('SET_SYSTEM_INFO', payload);
    },

    setFromRoute({commit}, payload) {
        commit('SET_FROM_ROUTE', payload);
    },

    setToRoute({commit}, payload) {
        commit('SET_TO_ROUTE', payload);
    },

}

const getters = {

    getUser() {
        return state.user;
    },
    getUserLevel() {
        return state.user.user_level;
    },
    getIsLoggedIn() {
        return state.is_logged_id;
    },
    getCompany() {
        return state.company;
    },
    getToken() {
        return state.token;
    },
    getTokenMd5() {
        return state.tokenMd5;
    },
    getTokenParam() {
        return state.tokenParam;
    },
    getTokenParamWithTokenMd5() {
        return state.tokenParam + state.tokenMd5;
    },
    getUserTypes() {
        return state.userTypes;
    },
    getSystemInfo() {
        return state.systemInfo;
    },
    getFromRoute() {
        return state.fromRoute;
    },
    getToRoute() {
        return state.toRoute;
    },

}

const user = {
    state,
    mutations,
    actions,
    getters

}

export default user;